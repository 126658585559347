import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";
import {CSSProperties} from "react";

export default function CheckoutSidebar({ product, destination }: { product: ProductModel, destination: DestinationModel })
{
    return (
        <>

            <div className="card shadow-sm mb-4">
                <div className="card-body">

                    <div className="row">
                        <div className="col-auto">
                            <img src={product.ProductImage ?? destination.SPDestinationImage} alt="image"
                                 className="rounded-4 img-fluid" style={{ maxWidth: '100px' } as CSSProperties}/>
                        </div>

                        <div className="col">

                            <strong className="d-block fs-6 fw-bold">{product.ProductName}</strong>
                            <span className="d-block fs-7">{destination.SPDestinationName}</span>

                            {/*
                            <div className="row align-items-center my-2">
                                <div className="col-auto">
                                    <div className="d-flex align-items-center">
                                        <strong
                                            className="bg-orange rounded px-1 fs-8 text-white">4.5</strong>
                                        <span className="fs-7 ms-2">Muy buena</span>
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <a className="fs-7 text-muted text-decoration-underline" href="/">38
                                        reseñas</a>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>

                    <hr />

                    <div className="d-flex">
                        <div className="fs-7 ">
                            <strong className="d-block">Salida</strong>
                            <span className="d-block">Jueves Octubre 27, 2023</span>
                            <div className="opacity-50 fs-7">15:00 – 23:00</div>
                        </div>

                        <div className="border mx-3"></div>

                        <div className="fs-7 text-end">
                            <strong className="d-block">Regreso</strong>
                            <span className="d-block">Domingo Octubre 30,  2023</span>
                            <div className="opacity-50 fs-7">01:00 – 11:00</div>
                        </div>
                    </div>

                    <hr />

                    <div className="">
                        <div className="my-3">
                            <strong className="d-block">Total estadía:</strong>
                            <span className="d-block">3 Noches</span>
                        </div>

                        <div className="my-3">
                            <strong className="d-block">Alojamiento:</strong>
                            <span className="d-block">1 Habitación, 4 adultos</span>
                        </div>
                    </div>

                </div>
            </div>

            <div className="card shadow-sm mb-4">
                <div className="card-body">

                    <h5 className="text-center mt-4 mb-5">Resumen de la orden</h5>

                    <div className="d-flex justify-content-between my-3">
                        <div className="col-auto"><strong>Hospedaje</strong></div>
                        <div className="col-auto"><span>$3.372.34 MXN</span></div>
                    </div>

                    <div className="d-flex justify-content-between my-3">
                        <div className="col-auto"><strong>Impuestos</strong></div>
                        <div className="col-auto"><span>$674.47 MXN</span></div>
                    </div>

                    <div className="d-flex justify-content-between my-3">
                        <div className="col-auto"><strong>Cargos adicionales</strong></div>
                        <div className="col-auto"><span>$200.00 MXN</span></div>
                    </div>

                    <div className="py-4 px-3 bg-light mx-n3 mb-n3 mt-4">
                        <div className="d-flex justify-content-between">
                            <div className="col-auto"><strong className="fs-5">Total</strong></div>
                            <div className="col-auto"><strong className="fs-5">$4.046.81 MXN </strong>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="card shadow-sm mb-4">
                <div className="card-body p-4">
                    <form>
                        <p className="mb-3">¿Tienes un cupón de descuento?</p>
                        <div className="d-flex">
                            <div className="form-floating flex-fill me-3">
                                <input className="form-control" type="text" name="CheckoutPromo"
                                       id="CheckoutPromo" placeholder="." />
                                <label htmlFor="CheckoutPromo" className="text-muted">Ingresa código
                                    del cupón</label>
                            </div>
                            <button className="btn btn-outline-blue">Canjear</button>
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}