import PageTitle from "../system/layouts/pageTitle.layout";
import React from "react";
import CheckoutHeader from "./checkoutHeader";
import CheckoutSteps from "./checkoutSteps";
import useProduct from "../../hooks/useProduct";
import ErrorPage from "../system/layouts/errorPage";
import {useParams} from "react-router-dom";

export default function CheckoutMain() {

    const { id:  productID} = useParams<{ id: string }>();
    const { product, destination, loading, empty} = useProduct(productID);

    if (loading)
    {
        return (<p>Cargando paquete...</p>);
    }
    else if (empty)
    {
        return (<ErrorPage title="Paquete no encontrado" message="El paquete que intenta ver no existe o ha sido eliminado." />);
    }


    return (<>
        <PageTitle title="Checkout"/>
        <CheckoutHeader product={product} destination={destination}/>
        <CheckoutSteps product={product} destination={destination}/>
    </>)
}