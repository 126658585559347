import React, {useEffect, useState} from "react";
import {ProductModel} from "../../models/productsModel";
import CategoryItem from "./categoryItem";
import categories, {CategoryModel} from "../../models/categoriesModel";

export default function CategoryItems({ category }: { category: CategoryModel })
{


    const [productList, setProductList] = useState<ProductModel[]>([])
    const [empty, setEmpty] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        const fetchData = async () => {

            if (category.SPCategoryID) {

                try {
                    const data = await categories.getProducts(category.SPCategoryID as string);
                    setProductList(data);
                } catch (error) {
                    setProductList([]);
                    setEmpty(true);

                }
                finally {
                    setLoading(false);
                }
            }

        };

        fetchData();
    }, []);


    return (
        <div className="">
            <div className="container">

                {loading && (<p>Cargando productos...</p>)}
                {!loading && empty && (<p>No existen productos...</p>)}
                {!loading && !empty && (
                <ul className="list-unstyled row row-cols-1 row-cols-sm-3 row-cols-md-3 g-5 py-4">
                    {productList.map((product) => (
                        <CategoryItem key={product.ProductID} product={product} />
                    ))}
                </ul>)}
            </div>
        </div>
    )
}