import api from './../utilities/api'
import {ProductModel, ProductModelDefault} from "./productsModel";
import {ErrorCourier} from "../utilities/muki/errorsMuki";

export interface DestinationModel {
    SPDestinationID? : string
    SPDestinationSlug? : string
    SPDestinationName? : string
    SPDestinationDescription? : string
    SPDestinationContentShort? : string
    SPDestinationContentFull? : string
    SPDestinationContentMETA? : {}
    SPDestinationContent? : string
    SPDestinationImage? : string
    SPDestinationImages? : string[]
    SPDestinationProducts? : ProductModel[]
    priceFrom? : number
    isPromo? : boolean
    duration? : string
    tags? : string[]
}

const defaultDestination: DestinationModel =
    {
        SPDestinationID: "",
        SPDestinationSlug: "",
        SPDestinationName: "",
        SPDestinationDescription: "",
        SPDestinationContentShort: "",
        SPDestinationContentFull: "",
        SPDestinationContentMETA: {},
        SPDestinationContent: "",
        SPDestinationImage: "",
        SPDestinationImages: [],
        SPDestinationProducts: [],
        priceFrom: 0,
        isPromo: false,
        duration: "",
        tags: [],
    }

const getAll = async (): Promise<DestinationModel[]>  => {

    const responseCourier = await api.get('destinations');
    const data = responseCourier.getData();

    if (!Array.isArray(data) || data.length < 1)
    {
        throw new ErrorCourier(responseCourier, "No existen destinos");
    }

    return data.map(item => ({...defaultDestination, ...item}));
}

const getRandom = async (items: number): Promise<DestinationModel[]>  => {

    const responseCourier = await api.get(`destinations/random/${items}`);
    const data = responseCourier.getData();

    if (!Array.isArray(data) || data.length < 1)
    {
        throw new ErrorCourier(responseCourier, "No existen destinos");
    }

    return data.map(item => ({...defaultDestination, ...item}));
}

const get = async (slug: string): Promise<DestinationModel> => {

    const responseCourier = await api.get(`destinations/${slug}`);
    const data = responseCourier.getData();

    if (typeof data !== 'object' || Array.isArray(data)) {

        throw new ErrorCourier(responseCourier, "No existe el destino");
    }

    return {...defaultDestination, ...data};
}

const getProducts = async (destinationId: string): Promise<ProductModel[]> => {

    const responseCourier = await api.get(`destinations/${destinationId}/products`);
    const products = responseCourier.getData();

    if (!Array.isArray(products) || products.length < 1)
    {
        throw new ErrorCourier(responseCourier, "No existen productos");
    }

    return products.map(item => ({...ProductModelDefault, ...item})) as ProductModel[]
}


const destinations = {
    getAll, get, getProducts, getRandom
}

export default destinations;