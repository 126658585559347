import React from "react";
import {useDestinations} from "../../../hooks/useDestinations";

export const FooterDestinations = () => {

    const [destinationList, loading, empty] = useDestinations()

    if (loading || empty)
    {
        return null;
    }

    return (

            <ul className="list-unstyled text-center row row-cols-2 g-0 mb-0">

                {destinationList.slice(0, 10).map((destination) => (
                    <li key={destination.SPDestinationID}>
                        <a href={`/destinos/${destination.SPDestinationSlug}`} className="d-block py-2 text-dark">
                            {destination.SPDestinationName}
                        </a>
                    </li>
                ))}

            </ul>

    );

};