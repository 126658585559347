import React from "react";

const DestinationsHeader = () => <>

    <div className="mxi-heading-background py-5 bg-cover bg-overlay-green"
         style={{ "backgroundImage": "url(https://source.unsplash.com/random/400x300/?tayrona)"} as React.CSSProperties }>
        <div className="container">
            <ul className="mxi-breadcrumbs list-inline d-flex">
                <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                <li className="list-inline-item"><a className="text-white" href="/">Catalogo</a></li>
                <li className="list-inline-item"><a className="text-white" href="/">Paquetes</a></li>
            </ul>

            <div className="d-flex align-items-center justify-content-between">
                <div className="w-75">
                    <h1 className="text-white text-shadow">Destinos</h1>
                    <p className="lead text-white text-shadow">En UDG Travel, ofrecemos los mejores destinos para que descubras lo mejor de México.</p>
                </div>
                <div className="text-nowrap">
                    {/* <a href="/" className="btn btn-yellow btn-lg">Continuar</a> */}
                </div>
            </div>
        </div>
    </div>


</>

export default DestinationsHeader