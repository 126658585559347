import React from "react";
import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";

export default function ProductFooter({ product, destination }: { product: ProductModel, destination: DestinationModel })
{
    return (

        <div className="product-footer py-5 bg-pattern-green mt-5">
            <div className="container">
                <div className="card bg-yellow shadow-lg">
                    <div className="card-body d-flex">
                        <div className="text-start w-50 p-3">
                            <h5 className="text-blue fw-bold">Regístrese en nuestra boletín informativo</h5>
                            <p>Ahorre hasta un 50% en tours y viajes. Obtenga acceso instantáneo a precios más bajos.</p>
                            <form>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control bg-transparent border-white"
                                           placeholder="correo@ejemplo.com" aria-label="correo@ejemplo.com"
                                           aria-describedby="" />
                                        <button className="btn btn-orange" type="button">Suscribirme</button>
                                </div>
                            </form>
                        </div>
                        <div className="mx-2 border-start border-2 border-dotted border-white"></div>
                        <div className="text-end w-50 p-3">
                            <h5 className="text-blue fw-bold">Soporte al cliente 24/7</h5>
                            <p>Contáctenos ahora para tener respuesta a todas sus preguntas relacionadas con tours.</p>
                            <button className="btn btn-lg btn-blue px-5" type="button"><i
                                className="mdi mdi-microphone"></i> Chatea con nosotros
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}