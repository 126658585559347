import React from "react";
import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";

export default function CheckoutHeader({ product, destination }: { product: ProductModel, destination: DestinationModel })
{
    return (
        <div className="mxi-heading-background py-5 bg-cover bg-overlay-green"
             style={{"backgroundImage": "url(https://source.unsplash.com/random/400x300/?tayrona)"} as React.CSSProperties }>
            <div className="container">
                <ul className="mxi-breadcrumbs list-inline d-flex">
                    <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                    <li className="list-inline-item"><a className="text-white" href="/">Catalogo</a></li>
                    <li className="list-inline-item"><a className="text-white" href="/">Paquetes</a></li>
                </ul>

                <div className="d-flex align-items-center justify-content-between">
                    <div className="w-100">
                        <h1 className="text-white text-shadow">TERMINA TU COMPRA</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}