import React from "react";

const HomeBanner = () => (
    <div className="mxi-banner bg-pattern-yellow py-5">
        <div className="container">
            <div className="card bg-cover text-white shadow border-0"
                 style={{ "backgroundImage": "url(assets/img/home-newsletter.jpg)"} as React.CSSProperties }>
                <div className="card-body p-5">
                    <div className="row">
                        <div className="col-8">
                            <h2 className="mb-4">REGÍSTRESE PARA OFERTAS DE VIAJE, NOTICIAS E INSPIRACIÓN</h2>
                        </div>
                        <div className="col-6">
                            <p className="mb-4">Únase a nuestra comunidad de más de 300,000 lectores globales que
                                reciben correos electrónicos llenos de noticias, promociones y otras cosas buenas de
                                nuestro sitio web. </p>
                            <form>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" placeholder="correo@ejemplo.com"
                                           aria-label="Recipient's username" aria-describedby="button-addon2" />
                                        <button className="btn btn-orange" type="button"
                                                id="button-addon2">SUSCRIBIRME
                                        </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default HomeBanner;