import PageTitle from "../system/layouts/pageTitle.layout";
import React from "react";

const FAQ  = () => (<>

    <PageTitle title="Preguntas Frecuentes" />
    <div className="mxi-heading-background py-5 bg-cover bg-overlay-blue"
         style={{"backgroundImage": "url(https://source.unsplash.com/random/1200x800/?tayrona)"} as React.CSSProperties }>
        <div className="container">
            <ul className="mxi-breadcrumbs list-inline d-flex">
                <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                <li className="list-inline-item"><a className="text-white" href="/preguntas-frecuentes">Preguntas Frecuentes</a></li>
            </ul>
            <div className="row justify-content-center">
                <div className="col-sm-9">
                    <div className="contact-info-title text-center">
                        <h5 className="mb-3 text-orange">PREGUNTAS FRECUENTES</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="container">
        <div className="row">
            <div className="col-lg-10 col-offset-lg-1 mx-auto">

                <div>
                    <section>
                        <h5>¿Qué incluyen los paquetes de viaje?</h5>
                        <p>Nuestros paquetes de viaje incluyen alojamiento en hoteles de calidad, transporte interno, guías turísticos y algunas comidas. Los detalles específicos varían según el paquete seleccionado.</p>
                    </section>

                    <section>
                        <h5>¿Cómo puedo reservar un paquete de viaje?</h5>
                        <p>Puedes reservar un paquete de viaje en línea a través de nuestro sitio web o contactando a nuestro equipo de atención al cliente.</p>
                    </section>

                    <section>
                        <h5>¿Cuáles son las políticas de cancelación?</h5>
                        <p>Las políticas de cancelación varían según el paquete y la fecha de viaje. Te recomendamos revisar los términos y condiciones de cada paquete antes de realizar la reserva.</p>
                    </section>

                    <section>
                        <h5>¿Cómo puedo realizar el pago?</h5>
                        <p>Aceptamos pagos en línea con tarjetas de crédito y débito. También ofrecemos la opción de pago en efectivo en nuestras oficinas.</p>
                    </section>

                    <section>
                        <h5>¿Qué documentación necesito para viajar?</h5>
                        <p>Para viajar dentro de México, generalmente necesitarás una identificación oficial válida, como pasaporte o identificación oficial mexicana. Algunos destinos pueden requerir documentación adicional. Te recomendamos verificar los requisitos antes de tu viaje.</p>
                    </section>

                    <section>
                        <h5>¿Ofrecen seguro de viaje?</h5>
                        <p>Sí, ofrecemos opciones de seguro de viaje para garantizar tu tranquilidad durante el viaje. Puedes obtener más información sobre los planes de seguro disponibles en nuestro sitio web.</p>
                    </section>

                    <section>
                        <h5>¿Puedo hacer cambios a mi reserva?</h5>
                        <p>Dependiendo de la disponibilidad y las políticas de cambio, es posible realizar modificaciones a tu reserva. Te recomendamos comunicarte con nuestro equipo de atención al cliente para obtener asistencia.</p>
                    </section>

                    <section>
                        <h5>¿Cómo puedo contactar al servicio de atención al cliente?</h5>
                        <p>Puedes comunicarte con nuestro servicio de atención al cliente llamando al número proporcionado en nuestro sitio web o enviándonos un correo electrónico a la dirección indicada.</p>
                    </section>
                </div>


            </div>
        </div>
    </div>

</>)

export default FAQ;