import React from 'react';
import {FooterDestinations} from "./footerDestinations";

function Footer()
{
    return (
        <footer id="mxi-footer" className="">
            <div className="mxi-main-footer py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 align-self-center">
                            <img className="img-fluid" src="/assets/img/registro-turismo.png" />
                        </div>

                        <div className="col">
                            <h4 className="mb-4 text-blue text-center">Destinos populares</h4>
                            <FooterDestinations />
                        </div>

                        <div className="border-start col text-center">
                            <img className="img-fluid mb-4" src="/assets/img/brand/logo-footer.svg" />
                                <p><a href="mailto:ventas@udg.travel"
                                      className="fw-bold text-dark">ventas@udg.travel</a></p>
                                <p>TEL: <a href="/" className="fw-bold text-dark">800 123 2222</a></p>
                                <ul className="list-inline">
                                    <li className="list-inline-item"><a className="text-orange" target="_blank"
                                                                        href="http://twitter.com" rel="noreferrer"><span
                                        className="fs-4 mdi mdi-twitter"></span></a></li>
                                    <li className="list-inline-item"><a className="text-orange" target="_blank"
                                                                        href="http://facebook.com" rel="noreferrer"><span
                                        className="fs-4 mdi mdi-facebook"></span></a></li>
                                    <li className="list-inline-item"><a className="text-orange" target="_blank"
                                                                        href="http://pinterest.com" rel="noreferrer"><span
                                        className="fs-4 mdi mdi-pinterest"></span></a></li>
                                    <li className="list-inline-item"><a className="text-orange" target="_blank"
                                                                        href="http://instagram.com" rel="noreferrer"><span
                                        className="fs-4 mdi mdi-instagram"></span></a></li>
                                </ul>
                                <ul className="list-inline fs-7">
                                    <li className="list-inline-item"><a className="text-dark text-decoration-underline"
                                                                        href="terminos-y-condiciones">Términos de Servicio</a></li>
                                    <li className="list-inline-item"><a className="text-dark text-decoration-underline"
                                                                        href="aviso-privacidad">Aviso de privacidad</a></li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mxi-post-footer bg-blue text-white p-4 fs-7">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <ul className="list-inline m-0">
                                <li className="list-inline-item">Copyright © {new Date().getFullYear()}</li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">UDG Travel</li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">Todos Los Derechos Reservados</li>
                            </ul>
                        </div>
                        <div className="col-auto text-nowrap">
                            <ul className="list-inline m-0">
                                <li className="list-inline-item">Diseño:<a className="text-white fw-bold ms-1"
                                                                           target="_blank" href="src/components#">222959611</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;