import PageTitle from "../system/layouts/pageTitle.layout";
import React from "react";
import './../../styles/about.css'

const AboutUs = () => (<>
    <PageTitle title="Nosotros" />
    <div className="mxi-heading-background py-5 bg-cover bg-overlay-blue"
         style={{"backgroundImage": "url(https://source.unsplash.com/random/1200x800/?tayrona)"} as React.CSSProperties }>
        <div className="container">
            <ul className="mxi-breadcrumbs list-inline d-flex">
                <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                <li className="list-inline-item"><a className="text-white" href="/">Nosotros</a></li>
            </ul>
            <div className="row justify-content-center">
                <div className="col-sm-9">
                    <div className="contact-info-title text-center">
                        <h3 className="mb-3 text-orange">ACERCA DE NOSOTROS</h3>
                        <p className="lead text-white">UDG Travel es una agencia de viajes ubicada en Guadalajara desde 1990. Ofrecemos paquetes turísticos a diferentes destinos de México.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    {/* Top Copy */}
    <div className="container">
        <div className="row my-5 align-items-center justify-content-between">
            <div className="col-sm-4">
                <h3 className="text-caps text-blue fw-bold text-end">Nuestros servicios</h3>
            </div>
            <div className="border-end border-4 border-orange col-auto align-self-stretch"></div>
            <div className="col-sm-7">
                <p className="lead">En UDG Travel ofrecemos una amplia variedad de paquetes turísticos para que puedas disfrutar de los mejores destinos de México. Contáctanos y te asesoraremos para que puedas planificar tus próximas vacaciones.</p>
                <p>Contáctanos por teléfono al (33) 1234 5678 o por correo electrónico a info@udgtravel.com.</p>
            </div>
        </div>

        {/* Top Features */}
        <div className="row my-5 about-features">

            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body d-flex align-items-start">
                        <div><i
                            className="mdi mdi-umbrella-beach-outline d-block bg-blue rounded-circle text-white feature-icon fs-1"></i>
                        </div>
                        <div className="flex-fill ps-3">
                            <h5 className="text-orange">Paquetes todo incluido</h5>
                            <p>Disfruta de tus vacaciones sin preocuparte por nada, nuestros paquetes todo incluido te ofrecen la comodidad y tranquilidad que necesitas.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body d-flex align-items-start">
                        <div><i
                            className="mdi mdi-umbrella-beach-outline d-block bg-blue rounded-circle text-white feature-icon fs-1"></i>
                        </div>
                        <div className="flex-fill ps-3">
                            <h5 className="text-orange">Experiencias únicas</h5>
                            <p>Con UDG Travel tendrás la oportunidad de vivir experiencias únicas en los destinos más espectaculares de México.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="card">
                    <div className="card-body d-flex align-items-start">
                        <div><i
                            className="mdi mdi-umbrella-beach-outline d-block bg-blue rounded-circle text-white feature-icon fs-1"></i>
                        </div>
                        <div className="flex-fill ps-3">
                            <h5 className="text-orange">Atención personalizada</h5>
                            <p>En UDG Travel nos enfocamos en brindarte una atención personalizada y de calidad para que puedas disfrutar al máximo de tus vacaciones.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    {/* Details */}
    <div className="bg-light py-5">
        <div className="container">
            <div className="row align-items-between">
                <div className="col-sm-6">
                    <h3 className="text-blue mb-5">Viaja con UDG Travel y descubre México</h3>
                    <ul className="list-unstyled">
                        <li className="d-flex mb-2">
                            <span className="fs-4 text-yellow mdi mdi-white-balance-sunny me-3"></span>
                            <p>En UDG Travel nos apasiona el turismo y queremos que tú también disfrutes de todo lo que México tiene para ofrecer. Ofrecemos paquetes de viaje a medida para satisfacer tus necesidades y presupuesto.</p>
                        </li>
                        <li className="d-flex mb-2">
                            <span className="fs-4 text-yellow mdi mdi-cloud me-3"></span>
                            <p>Nuestro equipo de expertos en viajes está listo para ayudarte a planear tu próximo viaje, desde la elección del destino hasta la reserva de hoteles, vuelos y actividades.</p>
                        </li>
                        <li className="d-flex mb-2">
                            <span className="fs-4 text-yellow mdi mdi-lightning-bolt me-3"></span>
                            <p>No importa si quieres explorar las playas de Cancún, la cultura de la Ciudad de México o la naturaleza de Chiapas, estamos aquí para ayudarte a hacer realidad tus sueños de viaje y ofrecerte una experiencia única e inolvidable.</p>
                        </li>
                    </ul>
                </div>
                <div className="col-sm">
                    <img className="img-fluid" src="/assets/img/ccare.jpg" />
                </div>
            </div>
        </div>
    </div>


</>)

export default AboutUs;