import api from "../utilities/api";
import {ErrorCourier} from "../utilities/muki/errorsMuki";

export interface ProductModel {

    ProductID?: string
    ProductName?: string
    ProductSlug?: string
    ProductCategories?: []
    ProductTitle?: string
    ProductType?: string
    ProductContentShort?: string
    ProductContentFull?: string
    ProductContentMETA?: {}
    ProductContentIncludes?: string
    ProductContentNotIncludes?: string
    ProductDestinations?: []
    ProductDuration?: string
    ProductDurationTime?: string
    ProductDurationLabel?: string
    ProductPriceModel?: string
    ProductPrice?: number
    ProductPriceA?: number
    ProductPriceB?: number
    ProductPriceC?: number
    ProductPriceD?: number
    ProductPriceE?: number
    ProductImage?: string
    ProductImages?: {}
    ProductTags?: []
}

export const ProductModelDefault: ProductModel = {

    ProductID: "",
    ProductName: "",
    ProductSlug: "",
    ProductCategories: [],
    ProductTitle: "",
    ProductType: "",
    ProductContentShort: "",
    ProductContentFull: "",
    ProductDestinations: [],
    ProductContentMETA: {},
    ProductContentIncludes: "",
    ProductContentNotIncludes: "",
    ProductDuration: "",
    ProductDurationTime: "",
    ProductDurationLabel: "",
    ProductPriceModel: "",
    ProductPrice: 0.0,
    ProductPriceA: 0.0,
    ProductPriceB: 0.0,
    ProductPriceC: 0.0,
    ProductPriceD: 0.0,
    ProductPriceE: 0.0,
    ProductImage: "",
    ProductImages: {},
    ProductTags: []
}


const getAll = async (): Promise<ProductModel[]>  => {
    const responseCourier = await api.get('products');
    const data = responseCourier.getData();

    if (!Array.isArray(data) || data.length < 1) {
        throw new ErrorCourier(responseCourier, 'No existen productos');
    }
    return data.map(item => ({...ProductModelDefault, ...item}));
}

const getRandom = async (items: number): Promise<ProductModel[]>  => {
    const responseCourier = await api.get(`products/random/${items}`);
    const data = responseCourier.getData();

    if (!Array.isArray(data) || data.length < 1) {
        throw new ErrorCourier(responseCourier, 'No existen productos');
    }
    return data.map(item => ({...ProductModelDefault, ...item}));
}

const get = async (id: string): Promise<ProductModel> =>
{
    const responseCourier = await api.get(`products/${id}`);
    const data = responseCourier.getData();

    if (typeof data !== 'object' || Array.isArray(data)) {
        throw new ErrorCourier(responseCourier, 'No existe el producto');
    }

    return {...ProductModelDefault, ...data};

}

const products = {
    getAll, get, getRandom
}

export default products;