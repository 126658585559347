import DestinationsItem from "./destinationsItem";
import {useDestinations} from "../../hooks/useDestinations";

const DestinationsItems = () => {

    const [destinationList, loading, empty] = useDestinations()

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">

                    {loading && (
                        <p>Cargando destinos...</p>
                    )}
                    {empty && (
                        <p>No existen destinos...</p>
                    )}
                    {!loading && !empty && (
                        <ul className="list-unstyled row row-cols-3 g-4 py-4">
                            {destinationList.map((destination) => (
                                <DestinationsItem key={destination.SPDestinationID} destination={destination} />
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DestinationsItems;