import React, {useEffect} from "react";
import {ProductModel} from "../../models/productsModel";
import CheckoutStep1 from "./checkoutStep1";
import CheckoutStep2 from "./checkoutStep2";
import CheckoutStep3 from "./checkoutStep3";
import {DestinationModel} from "../../models/destinationsModel";
import CheckoutSidebar from "./checkoutSidebar";


export default function CheckoutSteps({ product, destination }: { product: ProductModel, destination: DestinationModel })
{
    const[checkoutStep, setCheckoutStep] = React.useState(1);

    const stepValue = (stepString: string) => {

        let step = 1;

        if (stepString) {
            step = parseInt(stepString.replace('#paso-', ''));

            // 3. revisar que no es mayor a 3 ni menor a 1
            if (step < 1 || step > 3) {
                step = 1;
            }
        }

        return step;
    }
    useEffect(() => {

        setCheckoutStep(stepValue(window.location.hash));

    }, [checkoutStep]);


    useEffect(() => {

        const handleHashChange = () => {
            setCheckoutStep(stepValue(window.location.hash ?? ""));
        };
        handleHashChange();

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);


    if (parseInt(product.ProductID as string) === 0)
    {
        return (<p>Cargando paquete...</p>);
    }





    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-9 col-md-7 col-lg-5">

                        <ul className="nav nav-pills justify-content-between mx-5 mt-5 pb-5 border-top" id="CheckoutTop"
                            role="tablist">
                            <li className="nav-item position-relative translate-middle-y" role="presentation">
                                <a className={`text-center m-auto btn btn-circle pt-2 ${checkoutStep === 1 ? 'btn-orange active' : 'btn-secondary'}`}
                                   id="checkout-gdata" data-bs-toggle="tab" href="#paso-1" type="button"
                                   role="tab" aria-controls="checkout-gdata-pane" aria-selected={checkoutStep === 1 ? 'true' : 'false'}
                                   onClick={(e) => checkoutStep !== 2 ? e.preventDefault() : undefined}

                                >1</a>
                                <strong
                                    className="position-absolute top-100 start-50 translate-middle-x pt-3 text-center fs-7"
                                    style={{"width": "150px"} as React.CSSProperties}>Datos del Viaje</strong>
                            </li>
                            <li className="nav-item position-relative translate-middle-y" role="presentation">
                                <a className={`text-center m-auto btn btn-circle pt-2 ${checkoutStep === 2 ? 'btn-orange active' : 'btn-secondary'}`}
                                   id="checkout-payment" data-bs-toggle="tab" href="#paso-2"
                                   type="button" role="tab" aria-controls="checkout-payment-pane"
                                   aria-selected={checkoutStep === 2 ? 'true' : 'false'}
                                   onClick={(e) => e.preventDefault()}

                                >2</a>
                                <strong
                                    className="position-absolute top-100 start-50 translate-middle-x pt-3 text-center fs-7"
                                    style={{"width": "150px"} as React.CSSProperties}>Pago</strong>
                            </li>
                            <li className="nav-item position-relative translate-middle-y" role="presentation">
                                <a className={`text-center m-auto btn btn-circle pt-2 ${checkoutStep === 3 ? 'btn-orange active' : 'btn-secondary'}`}
                                   id="checkout-complete" data-bs-toggle="tab" href="#paso-3"
                                   type="button" role="tab" aria-controls="checkout-complete-pane"
                                   aria-selected={checkoutStep === 2 ? 'true' : 'false'}
                                   onClick={(e) => e.preventDefault()}

                                >3</a>
                                <strong
                                    className="position-absolute top-100 start-50 translate-middle-x pt-3 text-center fs-7"
                                    style={{"width": "150px"} as React.CSSProperties}>Confirmación</strong>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row gx-5">

                    <div className="col-lg-8 col-md-7 col-sm-6">
                        <div className="tab-content mb-5">

                            <div className="tab-pane fade show active" role="tabpanel"
                                 aria-labelledby="checkout-complete" >
                                {checkoutStep === 1 && <CheckoutStep1 product={product} destination={destination} />}
                                {checkoutStep === 2 && <CheckoutStep2 product={product} destination={destination} />}
                                {checkoutStep === 3 && <CheckoutStep3 product={product} destination={destination} />}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-5 col-sm-6 position-relative">
                        <div className="position-sticky top-0 pb-4">
                            <CheckoutSidebar product={product} destination={destination} />
                        </div>
                    </div>

                </div>
            </div>
            
        </>
    )
}