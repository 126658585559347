import {useEffect, useState} from "react";
import products, {ProductModel} from "../models/productsModel";

export const useHomeProducts = (): [products: ProductModel[], productsLoading: boolean, productsEmpty: boolean] => {

    const [productList, setProducts] = useState<ProductModel[]>([]);
    const [productsLoading, setProductsLoading] = useState<boolean>(true);
    const [productsEmpty, setProductsEmpty] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {


            try {
                const response = await products.getRandom(4);
                setProducts(response);
            }
            catch (error) {
                setProductsEmpty(true);
                setProducts([]);
            }

        };

        fetchData().then(r => setProductsLoading(false));

    }, [productList.length]);

    return [productList, productsLoading, productsEmpty];

}