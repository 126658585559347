import React from "react";

const categoriesHeader = () => <>

    <div className="mxi-heading-background py-5 bg-cover bg-overlay-green"
         style={{ "backgroundImage": "url(https://source.unsplash.com/random/400x300/?tayrona)"} as React.CSSProperties }>
        <div className="container">
            <ul className="mxi-breadcrumbs list-inline d-flex">
                <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                <li className="list-inline-item"><a className="text-white" href="/">Catalogo</a></li>
                <li className="list-inline-item"><a className="text-white" href="/">Paquetes</a></li>
            </ul>

            <div className="d-flex align-items-center justify-content-between">
                <div className="w-75">
                    <h1 className="text-white text-shadow">¡Descubre México!</h1>
                    <p className="lead text-white text-shadow">En UDG Travel, te ofrecemos una amplia variedad de paquetes de viajes personalizados para que puedas descubrir lo mejor de México.</p>
                </div>
            </div>
        </div>
    </div>

</>

export default categoriesHeader;