import Header from "./components/system/templates/header";
import {Body} from "./components/system/templates/body";
import Footer from "./components/system/templates/footer";
import DefaultRoutes from "./routes/defaultRoutes";
import {Provider} from 'react-redux';
import store from './store/store';

export default function App() {
    return (

        <Provider store={store}>

        <Header></Header>
            <Body>
                <DefaultRoutes></DefaultRoutes>
            </Body>
            <Footer></Footer>
        </Provider>

    );
}