import CategoriesHeader from "./categoriesHeader";
import CategoriesItems from "./categoriesItems";
import CategoriesGallery from "./categoriesGallery";
import PageTitle from "../system/layouts/pageTitle.layout";

const CategoriesMain = () =>
    <>
        <PageTitle title="Categorías" />
        <CategoriesHeader />
        <CategoriesGallery />
        <CategoriesItems />
    </>

export default CategoriesMain;