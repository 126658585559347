
const API_URL = process.env.NODE_ENV === 'production'
    ? (process.env.REACT_APP_API_URL || '').toString().trim()
    : (process.env.REACT_APP_DEV_API_URL || '').toString().trim();

const API_TOKEN = process.env.NODE_ENV === 'production'
    ? (process.env.REACT_APP_API_TOKEN || '').toString().trim()
    : (process.env.REACT_APP_DEV_API_TOKEN || '').toString().trim();

const isLive = process.env.NODE_ENV === 'production'

export { API_URL, API_TOKEN, isLive };
