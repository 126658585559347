import DestinationHeader from "./destinationHeader";
import DestinationItems from "./destinationItems";
import DestinationGallery from "./destinationGallery";
import {Params, useParams} from "react-router-dom";
import PageTitle from "../system/layouts/pageTitle.layout";
import ErrorPage from "../system/layouts/errorPage";
import {useDestination} from "../../hooks/useDestination";

interface DestinationParams extends Params{
    id: string;
}
const DestinationMain = () =>
{


    const { destinationId } = useParams<DestinationParams>();
    const { destination, loading, error } = useDestination(destinationId as string);

    if (error)
    {
        return (<ErrorPage />);
    }
    else if (loading)
    {
        return (<p>Cargando destino...</p>);
    }
    else
    {
        return (<>
            <PageTitle title={destination.SPDestinationName} />
            <DestinationHeader destination={destination} />
            <DestinationGallery  destination={destination} />
            <DestinationItems  destination={destination} />

        </>)
    }


}

export default DestinationMain