import React from "react";
import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";
import ImageGallery from 'react-image-gallery';
import {GalleryImage, galleryItems} from '../../models/imagesModel'


export default function ProductGallery({ product, destination }: { product: ProductModel, destination: DestinationModel })
{
    const images: GalleryImage[] = galleryItems(product.ProductImages, destination.SPDestinationImages);

    if (images.length < 2)
    {
        return null;
    }

    return (
        <ImageGallery items={images} />
    )
}