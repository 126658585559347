import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from '../../../store/store';


function Header()
{
    const headerState = useSelector((state: RootState) => state.header);

    return (
        <header id="mxi-header" className={`bg-white shadow ${headerState.headerType === 'home'  && 'header-transparent'}`} style={{"zIndex" : "9999"} as React.CSSProperties}>
            <div className="container d-flex align-items-center justify-content-between">
                <div className="">
                    <Link to="/" className="d-block my-3">
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <object className="d-block brand" type="image/svg+xml" data={headerState.headerType === 'home' ? '/assets/img/brand/logo-header.svg' : '/assets/img/brand/logo-header-color.svg'}></object>
                    </Link>
                </div>
                <div className="d-flex align-items-center">
                    <div className="nav-menu">
                        <ul className="list-inline m-0 d-none d-md-flex">
                            <li className="list-inline-item m-0">
                                <Link to="/destinos" className="d-block p-2 text-blue">Destinos</Link>
                            </li>
                            <li className="list-inline-item m-0">
                                <Link to="/categorias" className="d-block p-2 text-blue">Paquetes</Link>
                            </li>
                            <li className="list-inline-item m-0">
                                <Link to="/nosotros" className="d-block p-2 text-blue">Nosotros</Link>
                            </li>
                            <li className="list-inline-item m-0">
                                <Link to="/preguntas-frecuentes" className="d-block p-2 text-blue">FAQ</Link>
                            </li>
                            <li className="list-inline-item m-0">
                                <Link to="/contacto" className="d-block p-2 text-blue">Contacto</Link>
                            </li>
                        </ul>
                        <button className="mobile-menu-toggle d-block d-md-none" ><i
                            className="mdi mdi-menu"></i></button>
                    </div>
                    <div className="border-start border-3 border-orange hstack mx-2"></div>
                    <div className="user-menu">
                        <ul className="list-inline m-0 d-flex">
                            <li className="list-inline-item m-0">
                                <Link to="/cuenta" className="p-2 text-blue" data-bs-toggle="dropdown"
                                      aria-expanded="false"><span className="mdi mdi-account-circle-outline"></span></Link>
                                {/*
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="src/components#">Action</a></li>
                                    <li><a className="dropdown-item" href="src/components#">Another action</a></li>
                                    <li><a className="dropdown-item" href="src/components#">Something else here</a></li>
                                </ul>
                                */}
                            </li>
                            <li className="list-inline-item m-0 d-none">
                                <Link to="/cart" className="p-2 text-blue" data-bs-toggle="dropdown"
                                      aria-expanded="false"><span className="mdi mdi-cart-outline"></span></Link>
                                {/*

                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="src/components#">Action</a></li>
                                    <li><a className="dropdown-item" href="src/components#">Another action</a></li>
                                    <li><a className="dropdown-item" href="src/components#">Something else here</a></li>
                                </ul>
                                */}
                            </li>

                            {/*
                            <li className="list-inline-item m-0">
                                <a className="p-2 text-blue" href="javascript:void(0)" data-bs-toggle="dropdown"
                                   aria-expanded="false"><span className="mdi mdi-heart-outline"></span></a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="src/components#">Action</a></li>
                                    <li><a className="dropdown-item" href="src/components#">Another action</a></li>
                                    <li><a className="dropdown-item" href="src/components#">Something else here</a></li>
                                </ul>
                            </li>
                            */}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default Header;