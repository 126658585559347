import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";
import {Button} from "react-bootstrap";
import {animateScroll} from "react-scroll/modules";

export default function CheckoutStep3({ product, destination }: { product: ProductModel, destination: DestinationModel })
{

    animateScroll.scrollTo(200, { duration: 0, smooth: false, offset: -70 });


    return (<>


        <h3 className="my-5 text-center text-blue">Confirmación</h3>

        <div className="alert alert-success text-center p-4 mb-5">
            <h4>¡Sistema, su pedido fue enviado con éxito!</h4>
            <p className="m-0">Los detalles de la reserva se han enviado a: demo@udg.travel</p>
        </div>

        <div className="row">

            <div className="col-xl-3 col-lg-6">
                <span className="d-block">Numero de la orden</span>
                <strong className="fw-bold">13119</strong>
            </div>

            <div className="col-xl-3 col-lg-6">
                <span className="d-block">Fecha</span>
                <strong className="fw-bold">27/10/2022</strong>
            </div>

            <div className="col-xl-3 col-lg-6">
                <span className="d-block">Total</span>
                <strong className="fw-bold">$40.10</strong>
            </div>

            <div className="col-xl-3 col-lg-6">
                <span className="d-block">Método de pago</span>
                <strong className="fw-bold">Tarjeta de Crédito</strong>
            </div>

        </div>

        <hr className="border my-5" />

        <h5 className="text-secondary text-center my-4">Datos Personales</h5>

        <div className="row mb-4">

            <div className="col-12">
                <div className="d-flex justify-content-between">
                    <span className="">Nombre</span>
                    <strong className="fw-bold">Diego Vutek</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Correo Electrónico</span>
                    <strong className="fw-bold">demo@udg.travel</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Teléfono</span>
                    <strong className="fw-bold">112 666 888</strong>
                </div>
            </div>

        </div>

        <div className="border-top mx-3 p-4 text-secondary">
            <h6 className="text-center mb-3">Acompañante 1</h6>
            <div className="row">

                <div className="col-12">
                    <div className="d-flex justify-content-between">
                        <span className="">Nombre</span>
                        <strong className="">Diego Vutek</strong>
                    </div>
                </div>

                <div className="col-12">
                    <div className="d-flex justify-content-between pt-3">
                        <span className="">Correo Electrónico</span>
                        <strong className="">demo@udg.travel</strong>
                    </div>
                </div>

                <div className="col-12">
                    <div className="d-flex justify-content-between pt-3">
                        <span className="">Teléfono</span>
                        <strong className="">112 666 888</strong>
                    </div>
                </div>

            </div>
        </div>

        <div className="border-top mx-3 p-4 text-secondary">
            <h6 className="text-center mb-3">Acompañante 2</h6>
            <div className="row">

                <div className="col-12">
                    <div className="d-flex justify-content-between">
                        <span className="">Nombre</span>
                        <strong className="fw-bold">Diego Vutek</strong>
                    </div>
                </div>

                <div className="col-12">
                    <div className="d-flex justify-content-between pt-3">
                        <span className="">Correo Electrónico</span>
                        <strong className="fw-bold">demo@udg.travel</strong>
                    </div>
                </div>

                <div className="col-12">
                    <div className="d-flex justify-content-between pt-3">
                        <span className="">Teléfono</span>
                        <strong className="fw-bold">112 666 888</strong>
                    </div>
                </div>

            </div>
        </div>

        <hr className="border my-5" />

        <div className="row">
            <h5 className="text-secondary text-center my-4">Dirección</h5>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Calle</span>
                    <strong className="fw-bold">Serro de las Marías 156</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Interior</span>
                    <strong className="fw-bold">15</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Ciudad</span>
                    <strong className="fw-bold">Atizapan</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Estado</span>
                    <strong className="fw-bold">Edomex</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Código Postal</span>
                    <strong className="fw-bold">32154</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">País</span>
                    <strong className="fw-bold">Estados Unidos</strong>
                </div>
            </div>

            <div className="col-12">
                <div className="d-flex justify-content-between pt-3">
                    <span className="">Notas:</span>
                    <strong className="fw-bold"></strong>
                </div>
            </div>

        </div>

        <hr className="border my-5" />

        <div>
            <Button className="btn btn-lg btn-orange d-block" onClick={(e) => e.preventDefault()}> <span
                className="mdi mdi-printer-outline"></span> Imprimir</Button>
        </div>


    </>);
}