import api from './../utilities/api'
import {ErrorCourier} from "../utilities/muki/errorsMuki";
import {ProductModel, ProductModelDefault} from "./productsModel";

export interface CategoryModel {
    SPCategoryID? : string
    SPCategorySlug? : string
    SPCategoryName? : string
    SPCategoryDescription? : string
    SPCategoryContentShort? : string
    SPCategoryContentFull? : string
    SPCategoryImage? : string
    SPCategoryImages? : string[]
    SPCategoryTags? : string[]
}

const defaultCategory: CategoryModel =
    {
        SPCategoryID: "",
        SPCategorySlug: "",
        SPCategoryName: "",
        SPCategoryDescription: "",
        SPCategoryContentShort: "",
        SPCategoryContentFull: "",
        SPCategoryImage: "",
        SPCategoryImages: [],
        SPCategoryTags: [],
    }

const getAll = async (): Promise<CategoryModel[]>  => {

    const responseCourier = await api.get('categories');
    const data = responseCourier.getData();

    if (!Array.isArray(data) || data.length < 1)
    {
        throw new ErrorCourier(responseCourier, 'No existen categorías.');
    }

    return data.map(item => ({...defaultCategory, ...item}));
}

const getCategory = async (slug: string): Promise<CategoryModel> =>
{
    const responseCourier = await api.get('categories/' + slug);
    const categories: CategoryModel = {...defaultCategory, ...responseCourier.getData()};

    if(!categories.SPCategoryID)
    {
        throw new ErrorCourier(responseCourier, 'No existe la categoría.');
    }

    return categories;

}

const getProducts = async (categoryId: string): Promise<ProductModel[]> => {

    const responseCourier = await api.get(`categories/${categoryId}/products`);
    const products = responseCourier.getData();

    if (!Array.isArray(products) || products.length < 1)
    {
        throw new ErrorCourier(responseCourier, "No existen productos");
    }

    return products.map(item => ({...ProductModelDefault, ...item})) as ProductModel[]
}

const categories = {
    getAll,
    getCategory,
    getProducts
}

export default categories;