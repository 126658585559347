import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {HeaderState} from './headerState';

const initialState: HeaderState = {
    headerType: 'default',
    user: '',
};

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setHeaderType: (state, action: PayloadAction<string>) => {
            state.headerType = action.payload;
        },
        setUser: (state, action: PayloadAction<string>) => {
            state.user = action.payload;
        },
        updateHeaderType: (state, action: PayloadAction<string>) => {
            state.headerType = action.payload;
        },
    },
});

export const { setHeaderType, setUser, updateHeaderType } = headerSlice.actions;

export default headerSlice.reducer;
