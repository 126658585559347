import {Helmet} from "react-helmet";
import React from "react";
import {animateScroll} from "react-scroll/modules";

export default function PageTitle({ title, description, keywords }: { title?: string, description?: string, keywords? : string }) {

    if (title)
    {
        title = `${title} | UdG Travel`;
    }
    else
    {
        title = "Paquetes turísticos para México | UdG Travel";
    }

    description = description ?? "UdG Travel es una agencia de viajes especializada en paquetes turísticos para México. Descubre nuestros destinos, ofertas y planes a medida para tus próximas vacaciones. ¡Contáctanos hoy mismo y empieza a planear tu aventura!";
    keywords = keywords ?? "paquetes de viaje, destinos turísticos, vacaciones en México, viajes a México, reservar vuelos, hoteles, tours, excursiones, playas paradisíacas, aventuras al aire libre, turismo cultural, viajes de lujo, ofertas de viaje, guías turísticos, transporte y traslados.";

    animateScroll.scrollTo(1, { duration: 0, smooth: false, offset: -70 });

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}