import React from "react";
import {HomeFeatureItems} from "./homeFeatureItems";
import {Link} from "react-router-dom";


const HomeFeature = () => {

    interface categoryInterface
    {
        name: string
        id: number

    }
    const categories: Array<categoryInterface> = [
        {} as categoryInterface,
    ]


    return (
        <div className="mxi-featured">
            <div className="container">
                {categories.map((category, index) => (

                    <div className="tab-content" id="myTabContent" key={index}>
                        <div className={`tab-pane fade ${index === 0 ? 'show active' : ''}`} id="tab-pane-1" role="tabpanel" aria-labelledby="tab-1">
                            <div className="swiper category-slider"><HomeFeatureItems category={category.id}/></div>
                            <div className="text-center my-3">
                                <Link to="/categorias" className="btn btn-lg btn-outline-blue px-5" >Ver Todos los Paquetes</Link>
                            </div>
                        </div>

                    </div>
                ))}

            </div>
        </div>
    );
};


export default HomeFeature;