import '../../styles/product.css'
import {useParams} from "react-router-dom";
import ProductHeader from "./productHeader";
import ProductDetails from "./productDetails";
import ProductFooter from "./productFooter";
import PageTitle from "../system/layouts/pageTitle.layout";
import ErrorPage from "../system/layouts/errorPage";
import useProduct from "../../hooks/useProduct";

const ProductMain = () =>
{

    const { id:  productID} = useParams<{ id: string }>();

    const { product, destination, loading, empty } = useProduct(productID);

    if (loading)
    {
        return (<p>Cargando paquete...</p>);
    }
    else if (empty)
    {
        return (<ErrorPage title="Paquete no encontrado" message="El paquete que intenta ver no existe o ha sido eliminado." />);
    }


    return (<>
        <PageTitle title={`${product.ProductName} - ${destination.SPDestinationName}`} />
        <ProductHeader product={product} destination={destination} />
        <ProductDetails  product={product} destination={destination} />
        <ProductFooter  product={product} destination={destination} />

    </>)
}

export default ProductMain