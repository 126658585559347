import React from "react";
import {DestinationModel} from "../../models/destinationsModel";

export default function DestinationGallery({ destination }: { destination: DestinationModel })
{
    return (<>

{/*


        <div className="bg-pattern-yellow">
            <div className="container py-5">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="swiper category-slider">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <img className="img-fluid" src="https://source.unsplash.com/random/800x600/?oaxaca" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?beach" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?breeze" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?sea" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?vallarta" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?hermosillo" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?acapulco" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?puebla" />
                                </div>
                                <div className="swiper-slide"><img className="img-fluid"
                                                                   src="https://source.unsplash.com/random/800x600/?oaxaca" />
                                </div>
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                    <div className="col-md-5 text-end">
                        <h2 className="text-blue mb-4">Pellentesque mollis eget diam at molestie. Proin dapibus euismod
                            cursus.</h2>
                        <p className="lead">Cras at tempus orci. Pellentesque id fermentum ipsum. Vivamus consequat
                            viverra pellentesque. Ut ac lacus vel metus ullamcorper accumsan quis non arcu.</p>
                        <p>Nam lorem lacus, eleifend quis sapien aliquam, auctor porttitor ipsum. In hac habitasse
                            platea dictumst. Donec varius odio a mollis luctus. Duis vitae mi eget metus ultricies
                            vestibulum eget non nunc. Maecenas ac risus ac felis varius mattis ut eu eros. In eu libero
                            aliquet, pharetra urna pulvinar, vulputate erat. Maecenas et leo orci. In sapien sapien,
                            blandit viverra sodales nec, sagittis ut eros.</p>
                    </div>
                </div>
            </div>
        </div>

*/}

</>    );
}