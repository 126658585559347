import DestinationsHeader from "./destinationsHeader";
import DestinationsItems from "./destinationsItems";
import PageTitle from "../system/layouts/pageTitle.layout";

const DestinationsMain = () =>
{
    return (<>
        <PageTitle title="Destinos" />
        <DestinationsHeader />
        <DestinationsItems />

    </>)
}

export default DestinationsMain