import '../../styles/home.css'
import HomeIntro from "./homeIntro";
import HomeFeature from "./homeFeature";
import HomeDestinations from "./homeDestinations";
import HomeBanner from "./homeBanner";
import PageTitle from "../system/layouts/pageTitle.layout";
import {useDispatch} from 'react-redux';
import {updateHeaderType} from '../../store/header/headerSlice';

import {useEffect} from "react";

const Home = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        // Cuando se monta el componente, actualizar el estado del header
        dispatch(updateHeaderType('home'));

        // Cuando se desmonta el componente, volver al estado predeterminado del header
        return () => {
            dispatch(updateHeaderType('default' ));
        };
    }, [dispatch]);


    return (<>
        <PageTitle />
        <HomeIntro></HomeIntro>
        <HomeFeature></HomeFeature>
        <HomeDestinations></HomeDestinations>
        <HomeBanner></HomeBanner>
    </>)
}

export default Home;