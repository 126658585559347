import {CategoryModel} from "../../models/categoriesModel";
import React from "react";
import DOMPurify from 'dompurify';

export default function CategoryHeader({ category }: { category: CategoryModel })
{


    return (

        <div className="mxi-heading-background py-5 bg-cover bg-overlay-green"
             style={{ "backgroundImage": "url(https://source.unsplash.com/collection/928423/400x300?tayrona)"} as React.CSSProperties }>
            <div className="container">
                <ul className="mxi-breadcrumbs list-inline d-flex">
                    <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                    <li className="list-inline-item"><a className="text-white" href="/">Catalogo</a></li>
                    <li className="list-inline-item"><a className="text-white" href="/">Paquetes</a></li>
                </ul>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="w-75">
                        <h1 className="text-white text-shadow mb-3">{category.SPCategoryName}.</h1>

                        <p className="lead text-white text-shadow" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(category.SPCategoryContentShort as string) }} />
                    </div>
                </div>
            </div>
        </div>

    )
}