import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";
import {animateScroll} from "react-scroll/modules";

export default function CheckoutStep2({ product, destination }: { product: ProductModel, destination: DestinationModel })
{

    animateScroll.scrollTo(200, { duration: 0, smooth: false, offset: -70 });

    return (
        <>

            <h3 className="my-5 text-center text-blue">Pago</h3>

            <div className="row g-5">
                <div className="col-12">
                    <div className="form-floating">
                        <select className="form-select" required >
                            <option selected disabled>Selecciona</option>
                            <option value="credit">Tarjeta de Crédito</option>
                            <option value="debit">Tarjeta de Débito</option>
                        </select>
                        <label className="text-secondary">Método de pago</label>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="row g-5">
                        <div className="col-12">
                            <div className="form-floating">
                                <input className="form-control" type="text"
                                       placeholder="." required />
                                <label className="text-secondary">Nombre *</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                                <input className="form-control" type="text"
                                       placeholder="." required />
                                <label className="text-secondary">Número de tarjeta *</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input className="form-control" type="text"
                                       placeholder="." required />
                                <label className="text-secondary">Fecha de Expiración *</label>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-floating">
                                <input className="form-control" type="text"
                                       placeholder="." required />
                                <label className="text-secondary">CVC *</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <img src="/assets/img/checkout-card.png" alt="image" className="img-fluid" />
                </div>
            </div>

            <hr className="my-5 border" />

            <div className="row">
                <div className="col-12">
                    <div className="d-flex items-items-center justify-content-between">
                        <div className="me-5">
                            <div className="fs-7 text-secondary">Al continuar con esta
                                transacción, usted está de acuero con la política de privacidad,
                                así como los términos y condiciones de UDG Travel.
                            </div>
                        </div>

                        <div className="">
                            <a href="#paso-3" className="btn btn-outline-blue text-nowrap">Finalizar
                                Compra <span className="mdi mdi-arrow-top-right"></span></a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}