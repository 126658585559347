import React from "react";
import '../../styles/products.css';
// @ts-ignore
import {v4 as uuidv4} from 'uuid'
import {DestinationModel} from "../../models/destinationsModel";

const DestinationsItem = ({ destination }: { destination: DestinationModel }) => {

    if (!destination.SPDestinationName) {
        return null;
    }

    return (
        <li key={destination.SPDestinationID} className="col">
            <div className="card mxi-card rounded-4 border-0 shadow-sm overflow-hidden">
                <a href={`/destinos/${destination.SPDestinationSlug}`}
                   className="card-display ratio ratio-4x3 position-relative bg-fit-landscape hover animated"
                   style={{ "backgroundImage": `url(${destination.SPDestinationImage})`} as React.CSSProperties }>

                    <div
                        className="py-4 px-3 text-white display-title animated d-flex flex-column justify-content-end">
                        <h4>{destination.SPDestinationName}</h4>
                        <div className="d-flex align-items-center justify-content-between fs-8">
                            {/*<div className="left"><span
                                className="mdi mdi-clock-outline fs-8 me-1"></span>{destination.duration}
                            </div>*/}
                            {destination.tags?.length ? (
                            <div className="right">
                                {destination.tags.map((tag, index) => (<span className="badge text-bg-blue" key={index}>{tag}</span>))}
                            </div>) : null}
                        </div>
                    </div>

                </a>
            </div>
        </li>
    )
};

export default DestinationsItem;