import PageTitle from "../system/layouts/pageTitle.layout";
import React from "react";

const AccountRegister = () => (<>

    <PageTitle title="Registro" />
    <div className="container-fluid h-100">
        <div className="row h-100">
            <div className="col-lg-7 col-md-8 py-5 bg-cover bg-overlay-blue"
                 style={{"backgroundImage": "url(https://source.unsplash.com/random/1200x800/?tayrona)"} as React.CSSProperties }>

            </div>
            <div className="col-lg-5 col-md-4 d-flex flex-column align-items-center justify-content-center">

                <h3 className="text-blue mb-4">Regístrate</h3>

                <form>
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="floatingInput" placeholder="Nombre de usuario" />
                            <label htmlFor="floatingInput">Nombre de usuario</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="password" className="form-control" id="floatingEmail" placeholder="Email" />
                            <label htmlFor="floatingEmail">Email</label>
                    </div>

                    <hr />

                        <div className="form-floating mb-3">
                            <input type="password" className="form-control" id="floatingPassword"
                                   placeholder="Contraseña" />
                                <label htmlFor="floatingPassword">Contraseña</label>
                        </div>

                        <div className="form-floating mb-3">
                            <input type="password" className="form-control" id="floatingConfirmPassword"
                                   placeholder="Confirmar contraseña" />
                                <label htmlFor="floatingConfirmPassword">Confirmar contraseña</label>
                        </div>

                        <div className="form-group my-4 mx-3 text-secondary fs-7">
                            <input type="checkbox" className="custom-control-input" id="exampleCheck3" />
                                <label className="custom-control-label mb-0" htmlFor="exampleCheck3">He leído y estoy de
                                    acuerdo con la política de privacidad del sitio.</label>
                        </div>

                        <button type="submit" className="btn btn-lg d-block w-100 btn-orange mt-3">Completar Registro
                        </button>

                        <p className="text-center fs-7 my-3">¿Ya tienes una cuenta? <a href="/">Inicia
                            Sesión</a></p>

                        <hr className="my-4" />

                            <div className="log-reg-button d-sm-flex align-items-center justify-content-between">
                                <button type="submit" className="btn btn-facebook me-1"><i
                                    className="mdi mdi-facebook"></i> Entra con Facebook
                                </button>
                                <button type="submit" className="btn btn-google ms-1"><i
                                    className="mdi mdi-google"></i> Entra con Google
                                </button>
                            </div>

                </form>

            </div>
        </div>
    </div>

</>)

export default AccountRegister;