import PageTitle from "../system/layouts/pageTitle.layout";
import React from "react";

const AvisoPrivacidad = () => (<>

    <PageTitle title="Aviso de Privacidad" />

    <div className="mxi-heading-background py-5 bg-cover bg-overlay-blue"
         style={{"backgroundImage": "url(https://source.unsplash.com/random/1200x800/?tayrona)"} as React.CSSProperties }>
        <div className="container">
            <ul className="mxi-breadcrumbs list-inline d-flex">
                <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                <li className="list-inline-item"><a className="text-white" href="/">Política de privacidad</a></li>
            </ul>
            <div className="row justify-content-center">
                <div className="col-sm-9">
                    <div className="contact-info-title text-center">
                        <h3 className="mb-3 text-orange">POLÍTICA DE PRIVACIDAD</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="container">
        <div className="row">
            <div className="col-lg-10 col-offset-lg-1 mx-auto">

                <p><span className="text-info fst-italic">[Nombre completo del responsable si se trata de una persona física, o denominación o razón social en caso de ser una persona moral. En su caso, se sugiere incluir el nombre comercial]</span>,
                    con domicilio en <span className="text-info fst-italic">[indicar calle, número, colonia, ciudad, municipio o delegación, código postal y entidad federativa del domicilio para oír y recibir notificaciones]</span>,
                    es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo
                    siguiente:</p>

                <h4 className="text-orange mt-5 mb-4">¿Para qué fines utilizaremos sus datos personales?</h4>

                <p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son
                    necesarias para el servicio que solicita:</p>

                <ul>
                    <li><span className="text-info fst-italic">[Finalidad principal A]</span></li>
                    <li><span className="text-info fst-italic">[Finalidad principal B]</span></li>
                    <li><span className="text-info fst-italic">[Finalidad principal C]</span></li>
                </ul>

                <p>De manera adicional, utilizaremos su información personal para las siguientes finalidades
                    que <strong>no son necesarias</strong> para el servicio solicitado, pero que nos permiten y
                    facilitan brindarle una mejor atención:</p>

                <ul>
                    <li><span className="text-info fst-italic">[Finalidad secundaria A]</span></li>
                    <li><span className="text-info fst-italic">[Finalidad secundaria B]</span></li>
                </ul>

                <p>En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, desde
                    este momento usted nos puede comunicar lo anterior, <span className="text-info fst-italic">[descripción del mecanismo que tenga implementado el responsable. Nota,</span>
                    <span className="text-info fst-italic">el mecanismo de que se trate deberá permitir al titular negar su consentimiento previo a que sus datos personales sean tratados para estas finalidades].</span>
                </p>

                <p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para
                    que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>

                <h4 className="text-orange mt-5 mb-4">¿Qué datos personales utilizaremos para estos fines?</h4>

                <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los
                    siguientes datos personales: <span className="text-info fst-italic">[listado de datos personales o sus categorías. Para ejemplos de categorías ver el <a
                        target="_blank" href="http://abcavisosprivacidad.ifai.org.mx/#seccion6_01" rel="noreferrer">Glosario</a>].</span>
                </p>

                <p>Además de los datos personales mencionados anteriormente, para las finalidades informadas en el
                    presente aviso de privacidad utilizaremos los siguientes datos personales considerados como
                    sensibles, que requieren de especial protección: <span className="text-info fst-italic">[listado de datos personales sensibles o sus categorías. Para ejemplo de categorías ver el Glosario].</span>
                </p>

                <h5 className="text-blue mt-5 mb-4">¿Con quién compartimos su información personal y para qué
                    fines?</h5>

                <p>Le informamos que sus datos personales son compartidos dentro y fuera del país con las siguientes
                    personas, empresas, organizaciones y autoridades distintas a nosotros, para los siguientes
                    fines:</p>


                <div className="w-75 mx-auto my-5">
                    <table className="table table-sm table-bordered">
                        <thead>
                        <tr>
                            <th>Destinatario de los datos personales</th>
                            <th>País<span className="text-info fst-italic">(opcional)</span></th>
                            <th>Finalidad</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><span className="text-info fst-italic">Nombre del tercero receptor o sector al que pertenece</span>
                            </td>
                            <td><span className="text-info fst-italic">&nbsp;</span></td>
                            <td><span className="text-info fst-italic">Descripción de la finalidad</span></td>
                        </tr>
                        <tr>
                            <td><span className="text-info fst-italic">Nombre del tercero receptor o sector al que pertenece</span>
                            </td>
                            <td><span className="text-info fst-italic">&nbsp;</span></td>
                            <td><span className="text-info fst-italic">Descripción de la finalidad*</span></td>
                        </tr>
                        <tr>
                            <td><span className="text-info fst-italic">Nombre del tercero receptor o sector al que pertenece</span>
                            </td>
                            <td><span className="text-info fst-italic">&nbsp;</span></td>
                            <td><span className="text-info fst-italic">Descripción de la finalidad*</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p>Le informamos que para las transferencias indicadas con un asterisco (*) requerimos obtener su
                    consentimiento. Si usted no manifiesta su negativa para dichas transferencias, entenderemos que nos
                    lo ha otorgado <span
                        className="text-info fst-italic">[Ésto sólo aplica para consentimiento tácito].</span></p>

                <p>No autorizo que mis datos personales sean compartidos con los siguientes terceros <span
                    className="text-info fst-italic">[NOTA, este ejemplo de cláusula corresponde a un consentimiento tácito. Para ejemplos de cláusulas en las que se requiera el consentimiento expreso y expreso y por escrito ver numeral 7 de la sección III]</span>:
                </p>

                <ul>
                    <span className="text-info fst-italic">[Transferencia 1].</span>
                    <span className="text-info fst-italic">[Transferencia 2].</span>
                </ul>

                <h5 className="text-blue mt-5 mb-4">¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
                    oponerse a su uso?</h5>

                <p>Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las
                    condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su
                    información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación);
                    que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está
                    siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa
                    (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición).
                    Estos derechos se conocen como derechos ARCO.</p>

                <p>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva
                    en <span className="text-info fst-italic">[Describir los medios].</span></p>

                <p>Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, usted podrá llamar
                    al siguiente número telefónico <span className="text-info fst-italic">[...]</span>; ingresar a
                    nuestro sitio de Internet <span className="text-info fst-italic">[...]</span> a la sección <span
                        className="text-info fst-italic">[...]</span>, o bien ponerse en contacto con nuestro
                    Departamento de Privacidad, que dará trámite a las solicitudes para el ejercicio de estos derechos,
                    y atenderá cualquier duda que pudiera tener respecto al tratamiento de su información. Los datos de
                    contacto del Departamento de Privacidad son los siguientes:</p>

                <ul>
                    <li><span className="text-info fst-italic">[Domicilio, teléfono, correo electrónico del departamento de datos o de la persona].</span>
                    </li>
                </ul>

                <h5 className="text-blue mt-5 mb-4">¿Cómo puede revocar su consentimiento para el uso de sus datos
                    personales?</h5>

                <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus
                    datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos
                    atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna
                    obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar
                    que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir
                    prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>

                <p>Para revocar su consentimiento deberá presentar su solicitud en <span
                    className="text-info fst-italic">[Describir los medios].</span></p>

                <p>Para conocer el procedimiento y requisitos para la revocación del consentimiento, usted podrá llamar
                    al siguiente número telefónico <span className="text-info fst-italic">[...]</span>; ingresar a
                    nuestro sitio de Internet <span className="text-info fst-italic">[...]</span> a la sección <span
                        className="text-info fst-italic">[...]</span>, o bien ponerse en contacto con nuestro
                    Departamento de Privacidad.</p>

                <h5 className="text-blue mt-5 mb-4">¿Cómo puede limitar el uso o divulgación de su información
                    personal?</h5>

                <p>Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos
                    los siguientes medios:</p>

                <ul>
                    <li>Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la
                        Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean
                        utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor
                        información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o
                        bien ponerse en contacto directo con ésta.
                    </li>
                    <li>Su registro en el listado de exclusión <span className="text-info fst-italic">“[Nombre del listado]”,</span> a
                        fin de que sus datos personales no sean tratados para fines mercadotécnicos, publicitarios o de
                        prospección comercial por nuestra parte. Para mayor información llamar al número
                        telefónico <span className="text-info fst-italic">[...]</span>, enviar un correo electrónico a
                        la siguiente dirección <span className="text-info fst-italic">[...]</span>, o bien, consultar
                        nuestra página de Internet <span className="text-info fst-italic">[...]</span>.
                    </li>
                </ul>

                <h5 className="text-blue mt-5 mb-4">El uso de tecnologías de rastreo en nuestro portal de Internet</h5>

                <p>Le informamos que en nuestra página de Internet utilizamos <em>cookies</em>, <em>web beacons</em> y
                    otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de
                    Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra
                    página.</p>

                <p>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: <span
                    className="text-info fst-italic">[descripción de datos personales],</span> mismos que utilizamos
                    para <span className="text-info fst-italic">[descripción de finalidades].</span> Asimismo, le
                    informamos que compartiremos estos datos con:</p>


                <div className="w-75 mx-auto my-5">

                    <table className="table table-sm table-bordered">
                        <thead>
                        <tr>
                            <th>Destinatario de los datos personales</th>
                            <th>País <span className="text-info fst-italic">(opcional)</span></th>
                            <th>Finalidad</th>
                        </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td><span className="text-info fst-italic">Nombre del tercero receptor o sector al que pertenece</span></td>
                                <td><span className="text-info fst-italic">&nbsp;</span></td>
                                <td><span className="text-info fst-italic">Descripción de la finalidad</span></td>
                            </tr>

                            <tr>
                                <td><span className="text-info fst-italic">Nombre del tercero receptor o sector al que pertenece</span></td>
                                <td><span className="text-info fst-italic">&nbsp;</span></td>
                                <td><span className="text-info fst-italic">Descripción de la finalidad</span></td>
                            </tr>
                            <tr>
                                <td><span className="text-info fst-italic">Nombre del tercero receptor o sector al que pertenece</span></td>
                                <td><span className="text-info fst-italic">&nbsp;</span></td>
                                <td><span className="text-info fst-italic">Descripción de la finalidad</span></td>
                            </tr>

                        </tbody>
                    </table>
                </div>


                <p>&nbsp;</p>

            <p>Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: <span className="text-info fst-italic"><em>[descripción del procedimiento]</em>.</span></p>

            <p>Para mayor información sobre el uso de estas tecnologías, puede consultar el sitio de Internet <span className="text-info fst-italic">[...]</span></p>

            <h5 className="text-blue mt-5 mb-4">¿Cómo puede conocer los cambios a este aviso de privacidad?</h5>

            <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>

            <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de <span className="text-info fst-italic">[descripción del medio].</span></p>

            <p>El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente: <span className="text-info fst-italic">[descripción del procedimiento</span> <em>].</em></p>

            <p>&nbsp;</p>

            <p className="text-end"><span>Última actualización <strong>[día/mes/año]</strong></span>.</p>

            </div>
        </div>
    </div>


</>
            )

export default AvisoPrivacidad;