import {API_TOKEN, API_URL} from './config';
import {ResponseCourier} from "./muki/responsesMuki";


const sendRequest = async (method: string, path: string, data?: object, options?: object) => {
    path = path.replace(/^\/|\/$/g, '');
    const endpoint = API_URL.replace(/^\/|\/$/g, '') + '/'

    const response = await fetch(endpoint + path, {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${API_TOKEN}`
        },
        body: data ? JSON.stringify(data) : undefined,
        ...options
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Something went wrong.');
    }

    return new ResponseCourier().importFetchResponse(response);

};

const get = (path: string, data?: object, options?: object) => sendRequest('GET', path, data, options);

const post = (path: string, data?: object, options?: object) => sendRequest('POST', path, data, options);
const push = (path: string, data?: object, options?: object) => sendRequest('PUSH', path, data, options);

const put = (path: string, data?: object, options?


    : object) => sendRequest('PUT', path, data, options);

const deleteR = (path: string, data?: object, options?: object) => sendRequest('DELETE', path, data, options);

const api = {
    get,
    post,
    push,
    put,
    deleteR
};


export default api;