import {ProductModel} from "../../models/productsModel";
import React, {useState} from "react";
import {DestinationModel} from "../../models/destinationsModel";
import {Collapse} from 'react-bootstrap';
import {animateScroll} from "react-scroll/modules";


export default function CheckoutStep1({ product, destination }: { product: ProductModel, destination: DestinationModel })
{
    const [passengerOpen1, setPassengerOpen1] = useState(false);
    const [passengerOpen2, setPassengerOpen2] = useState(false);

    animateScroll.scrollTo(200, { duration: 0, smooth: false, offset: -70 });

    return (
        <>
            <h3 className="my-5 text-center text-blue">Datos del Viaje</h3>

            <div className="row">
                <div className="col-12">
                    <div className="form-floating my-3">
                        <input className="form-control" type="text" placeholder="." required />
                        <label className="text-secondary">Nombre Completo *</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating my-3">
                        <input className="form-control" type="email" placeholder="." required />
                        <label className="text-secondary">Correo Electrónico *</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating my-3">
                        <input className="form-control" type="text" placeholder="." required />
                        <label className="text-secondary">Número Telefónico *</label>
                    </div>
                </div>

                <div className="col-12 face my-3">
                    <button
                        className="btn btn-lg btn-light border d-flex align-items-center justify-content-between w-100"
                        type="button" data-bs-toggle="collapse"
                        data-bs-target="#CheckoutGdataPassenger1" aria-expanded="false"
                        aria-controls="CheckoutGdataPassenger1"
                        onClick={() => setPassengerOpen1(!passengerOpen1)}>
                        <span>Datos del Acompañante 1</span>
                        <span className="mdi mdi-plus"></span>
                    </button>
                    <Collapse in={passengerOpen1}>
                    <div className="border-bottom border-start border-end px-5"
                         id="CheckoutGdataPassenger1">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating my-3">
                                    <input className="form-control" type="text"
                                           placeholder="." />
                                    <label className="text-secondary">Nombre Completo</label>
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="form-floating my-3">
                                    <input className="form-control" type="email"
                                           placeholder="." />
                                    <label className="text-secondary">Correo Electrónico</label>
                                </div>

                            </div>
                            <div className="col-md-6">

                                <div className="form-floating my-3">
                                    <input className="form-control" type="text"
                                           placeholder="." required />
                                    <label className="text-secondary">Número Telefónico</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    </Collapse>
                </div>

                <div className="col-12 face my-3">
                    <button
                        className="btn btn-lg btn-light border d-flex align-items-center justify-content-between w-100"
                        type="button" data-bs-toggle="collapse"
                        data-bs-target="#CheckoutGdataPassenger2" aria-expanded="false"
                        aria-controls="CheckoutGdataPassenger2"
                        onClick={() => setPassengerOpen2(!passengerOpen2)}>
                        <span>Datos del Acompañante 2</span>
                        <span className="mdi mdi-plus"></span>
                    </button>
                    <Collapse in={passengerOpen2}>
                    <div className="collapse border-bottom border-start border-end px-5"
                         id="CheckoutGdataPassenger2">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-floating my-3">
                                    <input className="form-control" type="text"
                                           placeholder="." />
                                    <label className="text-secondary">Nombre Completo</label>
                                </div>
                            </div>
                            <div className="col-md-6">

                                <div className="form-floating my-3">
                                    <input className="form-control" type="text"
                                           placeholder="." />
                                    <label className="text-secondary">Correo Electrónico</label>
                                </div>

                            </div>
                            <div className="col-md-6">

                                <div className="form-floating my-3">
                                    <input className="form-control" type="text"
                                           placeholder="." />
                                    <label className="text-secondary">Número Telefónico</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    </Collapse>
                </div>

            </div>

            <div className="row">
                <div className="col-12">

                    <h5 className="text-secondary text-center my-4">Dirección</h5>

                    <div className="form-floating my-3">
                        <input className="form-control" type="text"  placeholder="." required />
                        <label className="text-secondary">Dirección completa *</label>
                    </div>

                </div>
                <div className="col-md-4">

                    <div className="form-floating my-3">
                        <input className="form-control" type="text"  placeholder="." required/>
                        <label className="text-secondary">Ciudad *</label>
                    </div>

                </div>
                <div className="col-md-4">

                    <div className="form-floating my-3">
                        <input className="form-control" type="text"  placeholder="." required />
                        <label className="text-secondary">Estado *</label>
                    </div>

                </div>
                <div className="col-md-4">

                    <div className="form-floating my-3">
                        <input className="form-control" type="text"  placeholder="." required />
                        <label className="text-secondary">C.P. *</label>
                    </div>

                </div>

                <div className="col-12">

                    <div className="form-floating my-3">
                                            <textarea className="form-control" rows={6}
                                                      placeholder="." ></textarea>
                        <label className="text-secondary">Notas</label>
                    </div>

                </div>
            </div>

            <hr className="my-5 border" />

            <div className="row">
                <div className="col-12">
                    <div className="d-flex items-items-center justify-content-between">
                        <div className="me-5">
                            <div className="fs-7 text-secondary">Al continuar con esta
                                transacción, usted está de acuero con la política de privacidad,
                                así como los términos y condiciones de UDG Travel.
                            </div>
                        </div>

                        <div className="">
                            <a href="#paso-2" className="btn btn-outline-blue text-nowrap" >Datos de
                                pago <span className="mdi mdi-arrow-top-right"></span></a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}