import {useEffect, useState} from "react";
import destinations, {DestinationModel} from "../models/destinationsModel";

interface DestinationState {
    destination: DestinationModel;
    loading: boolean;
    error: boolean;
}

export const useDestination = (destinationId: string): DestinationState => {
    const [state, setState] = useState<DestinationState>({
        destination: {},
        loading: true,
        error: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await destinations.get(destinationId);
                setState({
                    destination: response,
                    loading: false,
                    error: false,
                });
            } catch (error) {
                setState({
                    destination: {},
                    loading: false,
                    error: true,
                });
            }
        };

        fetchData();
    }, [destinationId]);

    return state;
};
