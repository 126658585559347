import {useEffect, useState} from "react";
import categories, {CategoryModel} from "../../models/categoriesModel";
import CategoriesItem from "./categoriesItem";

const CategoriesItems =  () => {

    const [categoryList, setCategories] = useState<CategoryModel[]>([]);
    const [empty, setEmpty] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await categories.getAll();
                setCategories(response);
            }
            catch (error) {
                setEmpty(true);
                setCategories([]);
            }

        };

        fetchData().then(r => setLoading(false));
    }, []);


    return (


        <div className="container">
            <div className="row">
                <div className="col-12">

                    {loading && (
                        <p>Cargando categorías...</p>
                    )}
                    {!loading && empty && (
                        <p>No hay categorías disponibles.</p>
                    )}
                    {!loading && !empty && (
                        <ul className="list-unstyled row row-cols-3 g-4 py-4">
                            {categoryList.map((category) => (
                                <CategoriesItem key={category.SPCategoryID} category={category} />
                            ))}
                        </ul>
                    )}

                </div>
            </div>
        </div>
    )
}

export default CategoriesItems;