import React from "react";
import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Link} from "react-router-dom";
import {useHomeProducts} from "../../hooks/useHomeProducts";


export function  HomeFeatureItems(props: {category: number}){

    const [products, productsLoading, productsEmpty] = useHomeProducts();

    if (productsLoading) {
        return <p>Loading...</p>;
    }

    if (productsEmpty) {
        return <p>No destinations found</p>;
    }

    return (
        <Swiper
            modules={[Pagination, Navigation]}
            pagination={{ clickable: true }}
            navigation
            breakpoints={{
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                // when window width is >= 480px
                480: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 3,
                    spaceBetween: 25
                },
                // when window width is >= 760px
                760: {
                    slidesPerView: 4,
                    spaceBetween: 30
                }
            }}
        >

            {products.map((product, index) => (
                <SwiperSlide key={index}>

                    <Link to={`paquete/${product.ProductSlug}`} className="card mxi-card">
                        <div className="card-frames topright">


                            {/*
                            {product.ProductPrice !== undefined && product.ProductPrice > 0 && (
                            <div className="main-frame bg-orange text-white">
                                <small className="d-block fs-8">Desde</small>

                                {product.ProductPrice !== undefined > 0 ? (
                                    <strong className="d-block"><s className="opacity-50">{numberFormat.format(product.priceBeforeDiscount ?? 0)}</s>{numberFormat.format(product.price ?? 0)}</strong>
                                ) : (
                                    <strong className="d-block">{numberFormat.format(product.price ?? 0)}</strong>
                                )}

                            </div>
                            )}

                            {product.isPromo ? (
                                <div className="small-frame bg-yellow text-blue d-inline-block">
                                    <strong className="fs-7">PROMO</strong>
                                </div>
                            ) : ""}*/}

                        </div>

                        <div className="card-display ratio ratio-16x9"
                             style={{"backgroundImage": `url(${product.ProductImage ?? 'https://source.unsplash.com/random/?cancun'})`} as React.CSSProperties }></div>
                        <div className="card-body">
                            <h5>{product.ProductName ?? 'Pellentesque vestibulum cursus nulla'}</h5>
                            <ul className="list-inline fs-7">
                                {product.ProductCategories && typeof product.ProductCategories === 'object' &&
                                    Object.values(product.ProductCategories).map((category, index) => {
                                        return (<li className="list-inline-item">{category}</li>)
                                    })
                                }
                            </ul>

                            {parseInt(product.ProductDuration as string) > 0 &&
                                (<div className="d-flex align-items-center justify-content-between fs-8">
                                        <div className="left"><span className="mdi mdi-clock-outline fs-8 me-1"></span>{product.ProductDuration} días
                                        </div>
                                        <div className="right"></div>
                                    </div>
                                )}

                        </div>
                    </Link>

                </SwiperSlide>
            ))}


        </Swiper>
    );
}
