import React from "react";
import {Link} from "react-router-dom";
import {useHomeDestinations} from "../../hooks/useHomeDestinations";

const HomeDestinations = () => {


    // implements useHomeDestinations
    const [destinations, destinationsLoading, destinationsEmpty] = useHomeDestinations();


    if (destinationsLoading) {
        // Código a ejecutar mientras se cargan los datos
        return <p>Loading...</p>;
    }

    if (destinationsEmpty) {
        // Código a ejecutar si no hay datos
        return <p>No destinations found</p>;
    }

    return (
        <div className="mxi-destinations">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-5 pe-5">
                        <h1 className="text-orange">Elige tu Destino</h1>
                        <hr className="hr-dash hr-left lightblue" />
                        <p className="fs-6 text-blue">¡Bienvenido a México! Este país lleno de cultura, historia y belleza natural te espera con los brazos abiertos. Desde las playas de ensueño en la Riviera Maya hasta las ciudades coloniales llenas de historia en el centro del país, México tiene algo para todos los gustos. Descubre los lugares más hermosos y fascinantes de México y vive una experiencia única e inolvidable.</p>
                        <p className="fs-6 text-blue">La cultura en México es vibrante y llena de color. Desde las ruinas de antiguas civilizaciones prehispánicas hasta las tradiciones populares que se celebran en todo el país, la cultura mexicana es una de las más ricas y diversas del mundo. Descubre la historia y la tradición de México y sumérgete en la cultura más fascinante de América Latina.</p>
                        <p className="fs-6 text-blue">La gastronomía en México es famosa en todo el mundo. Desde los platillos más tradicionales, como los tacos, tamales y mole, hasta los platillos más sofisticados y modernos, México tiene una de las cocinas más diversas y sabrosas de América Latina. Descubre los sabores más auténticos de México y vive una experiencia culinaria única e inolvidable.</p>
                        <p><a href="/" className="btn btn-lg btn-orange">DESCUBRE MÁS</a></p>
                    </div>
                    <div className="col-md-7">
                        <ul className="list-unstyled row row-cols-2 g-4 py-4">
                            {destinations.map((destination, index) => (
                                <li className="col" key={index}>
                                    <div className="card mxi-card rounded-4 border-0 shadow-sm overflow-hidden">

                                        <Link to={`/destinos/${destination.SPDestinationSlug}`}
                                           className="card-display ratio ratio-3x4x position-relative bg-fit-landscape hover animated"
                                           style={{ "backgroundImage": `url(${destination.SPDestinationImage ?? 'https://source.unsplash.com/random/400x300/?cancun?sig=1'})`} as React.CSSProperties }>


                                            <div
                                                className="py-4 px-3 text-white display-title animated d-flex flex-column justify-content-end">
                                                <div className="d-flex align-items-center justify-content-between fs-8">
                                                    <div className="left w-75">
                                                        <h4>{destination.SPDestinationName ?? 'Nombre Destino'}</h4>
                                                    </div>
                                                    <div className="right"></div>
                                                </div>
                                            </div>

                                        </Link>
                                    </div>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeDestinations;