import React from "react";
import '../../styles/products.css';
// @ts-ignore
import {v4 as uuidv4} from 'uuid'
import {CategoryModel} from "../../models/categoriesModel";

const CategoriesItem = ({ category }: { category: CategoryModel }) => {


    category.SPCategoryID = uuidv4();
    category.SPCategorySlug = category.SPCategorySlug ?? "hola-mundo"

    if (!category.SPCategoryName)
    {
        return null;
    }


    return (
            <li className="col" key={category.SPCategoryID}>
                <div className="card mxi-card rounded-4 border-0 shadow-sm overflow-hidden">
                    <a href={`/categorias/${category.SPCategorySlug}`}
                       className="card-display ratio ratio-4x3 position-relative bg-fit-landscape hover animated"
                       style={{ "backgroundImage": `url(${category.SPCategoryImage})`} as React.CSSProperties }>

                        <div
                            className="py-4 px-3 text-white display-title animated d-flex flex-column justify-content-end">
                            <h4>{category.SPCategoryName}</h4>
                            <div className="d-flex align-items-center justify-content-between fs-8">
                                {/*<div className="left"><span
                                className="mdi mdi-clock-outline fs-8 me-1"></span>{category.duration}
                            </div>*/}
                                {category.SPCategoryTags?.length ? (
                                    <div className="right">
                                        {category.SPCategoryTags.map((tag, index) => (<span key={index} className="badge text-bg-blue">{tag}</span>))}
                                    </div>) : null}
                            </div>
                        </div>

                    </a>
                </div>
            </li>
    )
};

export default CategoriesItem;