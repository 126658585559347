import {useEffect, useState} from "react";
import products, {ProductModel} from "../models/productsModel";
import destinations, {DestinationModel} from "../models/destinationsModel";

function useProduct(productID: string | undefined): {product: ProductModel, destination: DestinationModel, loading: boolean, empty: boolean} {


    const [product, setProduct] = useState<ProductModel>({})
    const [destination, setDestination] = useState<DestinationModel>({})
    const [loading, setLoading] = useState<boolean>(true);
    const [empty, setEmpty] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {

            if (productID !== '' && productID !== undefined)
            {
                try
                {
                    const data = await products.get(productID);
                    setProduct(data);
                }
                catch (error)
                {
                    setProduct({});
                    setEmpty(true);
                }
            }
        };

        fetchData().then(r => {
            setLoading(false);
            window.scrollTo(0, 0);
        });
    }, [productID]);

    useEffect(() => {
        const fetchData = async () => {

            if (typeof product.ProductDestinations === 'object' && !Array.isArray(product.ProductDestinations) && Object.keys(product.ProductDestinations).length > 0) {

                try {

                    const destinationID = product.ProductDestinations ? Object.keys(product.ProductDestinations)[0] : undefined;

                    if (destinationID !== undefined) {
                        const data = await destinations.get(destinationID as string);

                        setDestination(data);
                    }
                    else
                    {
                        setDestination({});
                    }
                }
                catch (error) {
                    setDestination({});
                }

            }
        };

        fetchData().then(r => setLoading(false));

    }, [product.ProductDestinations]);

    return {product, destination, loading, empty};


}
export default useProduct;