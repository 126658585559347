import React, {useEffect, useState} from "react";
import destinations, {DestinationModel} from "../../models/destinationsModel";
import {ProductModel} from "../../models/productsModel";
import DestinationItem from "./destinationItem";

export default function DestinationItems({ destination }: { destination: DestinationModel })
{


    const [productList, setProducts] = useState<ProductModel[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const [empty, setEmpty] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {


            try {
                const response = await destinations.getProducts(destination.SPDestinationID as string);
                setProducts(response);
            }
            catch (error) {
                setProducts([]);
                setEmpty(true);
            }

        };

        fetchData().then(r => {setLoading(false)});

    }, []);

    return (
        <div className="">
            <div className="container">

                {loading && (
                        <p>Cargando productos...</p>
                    )}

                    {!loading && empty && (
                        <div className="row justify-content-center my-5">
                            <div className="col-6">
                                <div className="alert alert-info text-center" role="alert">
                                    No hay destinos disponibles.
                                </div>
                            </div>
                        </div>
                    )}

                    {!loading && !empty && (
                                <ul className="list-unstyled row row-cols-1 row-cols-sm-3 row-cols-md-3 g-5 py-4">
                                    {productList.map((product) => (
                                        <DestinationItem key={product.ProductID} product={product} destination={destination} />
                                    ))}
                                </ul>
                    )}

            </div>
        </div>
    )
}