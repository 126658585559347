import PageTitle from "../system/layouts/pageTitle.layout";
import React from "react";

const Contacto = () => (<>

    <PageTitle title="Contacto" />

    <div className="mxi-heading-background py-5 bg-cover bg-overlay-blue"
         style={{"backgroundImage": "url(https://source.unsplash.com/random/1200x800/?tayrona)"} as React.CSSProperties }>
        <div className="container">
            <ul className="mxi-breadcrumbs list-inline d-flex">
                <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                <li className="list-inline-item"><a className="text-white" href="/">Contacto</a></li>
            </ul>
            <div className="row justify-content-center">
                <div className="col-sm-9">
                    <div className="contact-info-title text-center">
                        <h3 className="mb-3 text-orange">INFÓRMESE CON NOSOTROS</h3>
                        <p className="lead text-white">Sagittis posuere id nam quis vestibulum vestibulum a facilisi at
                            elit hendrerit scelerisque sodales nam dis orci.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container">
        <div className="row">
            <div className="col-lg-10 col-offset-lg-1 mx-auto">

                <div className="contact-info bg-white">

                    <div className="contact-info-content row mb-5">

                        <div className="col-lg-4 col-md-12 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="px-2 py-5 text-center rounded">
                                        <div className="info-icon mb-2"><i
                                            className="mdi mdi-map-marker-star text-blue fs-1"></i></div>
                                        <div className="info-content">
                                            <h5 className="text-blue">Nuestras Oficinas</h5>
                                            <p className="m-0 text-secondary">Lucas Alamán 32, Obrera, CP.06800 Ciudad
                                                de México, CDMX</p>
                                        </div>
                                        <button className="btn btn-orange mt-3">Mostrar el Mapa</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="px-2 py-5 text-center rounded">
                                        <div className="info-icon mb-2"><i
                                            className="mdi mdi-phone-classic text-blue fs-1"></i></div>
                                        <div className="info-content">
                                            <h5 className="text-blue">Número Telefónico</h5>
                                            <p className="m-0 text-secondary">+52 5565 666888</p>
                                            <p className="m-0 text-secondary">+52 5565 222000</p>
                                        </div>
                                        <button className="btn btn-orange mt-3">Haz una llamada</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="px-2 py-5 text-center rounded">
                                        <div className="info-icon mb-2"><i
                                            className="mdi mdi-email-fast-outline text-blue fs-1"></i></div>
                                        <div className="info-content">
                                            <h5 className="text-blue">Dirección de Correo</h5>
                                            <p className="m-0 text-secondary">info@udg.travel</p>
                                            <p className="m-0 text-secondary">ventas@udg.travel</p>
                                        </div>
                                        <button className="btn btn-orange mt-3">Envíanos un Mail!</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>

    <div className="container-fluid">
        <div id="contact-form1" className="contact-form">
            <div className="row">

                <div className="col-lg-6 position-relative p-0">

                    <div className="position-absolute top-0 left-0 right-0 bottom-0 ratio">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1881.3939589879512!2d-99.14332826775426!3d19.421567317392704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fed75e1b26f5%3A0xfa71fb8eb335a8ad!2sLucas%20Alam%C3%A1n%2032%2C%20Obrera%2C%20Cuauht%C3%A9moc%2C%2006800%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1671753661242!5m2!1ses!2smx"
                            title="Map" allowFullScreen></iframe>
                    </div>
                </div>

                <div className="col-lg-6 bg-cover bg-overlay-green d-flex align-items-center order-0 order-md-5"
                     style={{"backgroundImage": "url(https://source.unsplash.com/random/400x300/?tayrona)"} as React.CSSProperties }>
                    <div className="p-5" style={{"maxWidth": "600px"} as React.CSSProperties}>

                        <h3 className="text-yellow mb-4">Fusce ac massa eu nisl porttitor ultrices</h3>
                        <p className="lead text-white mb-4">Pellentesque lacinia, tellus sit amet condimentum tristique,
                            eros massa dignissim massa, vel blandit mauris justo a erat. Quisque in vulputate augue.</p>
                        <hr className="hr-dash hr-left yellow my-5" />
                            <form method="post" action="#" name="contactform2" id="contactform2"
                                  noValidate>
                                <div className="form-floating mb-3">
                                    <input type="text" name="first_name" className="form-control" id="fullname"
                                           placeholder="First Name" />
                                        <label htmlFor="fullname">Nombres</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" name="last_name" className="form-control" id="llastname"
                                           placeholder="Last Name" />
                                        <label htmlFor="llastname">Apellidos</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" name="email" className="form-control" id="email"
                                           placeholder="Email" />
                                        <label htmlFor="email">Correo Elecrtrónico</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="text" name="phone" className="form-control" id="phnumber"
                                           placeholder="Phone" />
                                        <label htmlFor="phnumber">Número Telefónico</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea className="form-control h-auto" rows={4} name="comments" id="comments"
                                              placeholder="Enter a message"></textarea>
                                    <label htmlFor="comments">Comentarios Adicionales</label>
                                </div>
                                <button type="submit" className="btn btn-lg btn-yellow w-100" id="submit2">Enviar
                                    Comentarios
                                </button>
                            </form>

                    </div>
                </div>

            </div>
        </div>
    </div>

</>)

export default Contacto;