import {ErrorContainer, ErrorEntry} from "./errorsMuki";

export interface ResponseManagerExport {
    success: boolean;
    data: Array<unknown> | Record<string, unknown>;
    errors?: [] | {} | null;
}

export class ResponseCourier {

    protected success: boolean;
    protected data: Array<unknown> | Record<string, unknown>;
    protected readonly errors: ErrorContainer = new ErrorContainer();
    private _statusCode: number = 200;

    constructor(data?: Array<unknown> | Record<string, unknown>) {
        this.success = true;
        this.data = data || [];
    }

    setData(data: Array<unknown> | Record<string, unknown>): ResponseCourier {
        this.data = data;
        return this;
    }

    getData(): Array<unknown> | Record<string, unknown> {
        return this.data;
    }

    getDataCount(): number {
        return this.dataCounter(this.data);
    }

    dataCounter(data: Array<unknown> | Record<string, unknown>): number {
        return Array.isArray(data) ? data.length : Object.keys(data).length;
    }

    public mapData(callback: any): Array<unknown> | Record<string, unknown> {

        if (Array.isArray(this.data)) {
            this.data = this.data.map(callback);
        }
        return this.data;
    }

    updateData(index: number, data: Record<string, unknown>) {
        if (Array.isArray(this.data)) {
            this.data[index] = data;
        }
    }

    isDataEmpty(): boolean {
        return this.getDataCount() === 0;
    }

    isDataArray(): boolean {
        return Array.isArray(this.data);
    }

    setErrors(errors: Array<ErrorEntry>): ResponseCourier {

        this.errors.setErrors(errors);
        return this;

    }

    addError(message: string, code?: string | number): ResponseCourier {

        this.errors.addError(message === "" ? "Unknown Error" : message, code);

        return this;

    }

    getErrors(): Array<ErrorEntry> {
        return this.errors.getErrors()
    }

    getErrorMessages(): Array<string> {
        return this.errors.getErrorMessages();
    }

    isSuccess(): boolean {
        return !this.errors.isError();
    }

    export(): ResponseManagerExport {
        return {
            success: this.success,
            data: this.data,
            errors: this.errors.exportErrors(),
        };
    }


    get statusCode(): number {
        return this._statusCode;
    }

    set statusCode(value: number) {
        this._statusCode = value;
    }

    async importFetchResponse(response: Response) {

        const data = await response.json();
        if (typeof data === "object" && !Array.isArray(data)) {

            if (data.hasOwnProperty("status") && data.status === 'error') {
                if (data.hasOwnProperty("errors") && typeof data.errors === "object") {
                    if (Array.isArray(data.errors) && data.errors.length > 0) {
                        data.errors.forEach((error: any) => {

                            try {
                                this.addError(error.message, error.code);
                            } catch (e) {
                            }

                        });

                    } else if (data.errors.hasOwnProperty("code") && data.errors.hasOwnProperty("message")) {
                        this.addError(data.errors.message, data.errors.code);
                    }
                }
            }
            else if (data.hasOwnProperty("status") && data.status === 'success')
            {
                if (data.hasOwnProperty("data") && typeof data.data === "object")
                {
                    this.setData(data.data);
                }
            }

        }

        this.statusCode = response.status;

        return this;
    }
}

