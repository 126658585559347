export interface ItemImage {
    id: string;
    small: string;
    source: string;
    regular: string;
    medium: string;
    thumbnail: string;
    description: string;
}

export interface GalleryImage {
    original: string;
    thumbnail: string;
}


export const placeholderImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==';

export const galleryItems = (imageContainer: null | undefined | {}, imageContainerB: null | undefined | {}): GalleryImage[] =>
{
    const productTotal: number = imageContainer !== null && imageContainer !== undefined && typeof imageContainer === "object" && !Array.isArray(imageContainer) ? Object.keys(imageContainer).length : 0;
    const destinationTotal: number = imageContainerB !== null && imageContainerB !== undefined && typeof imageContainerB === "object" && !Array.isArray(imageContainerB) ? Object.keys(imageContainerB).length : 0;

    const images: GalleryImage[] = [];
    const container = productTotal > destinationTotal ? imageContainer : imageContainerB;

    if (container !== null && container !== undefined && typeof container === "object" && !Array.isArray(container))
    {
        for (const [, value] of Object.entries(container))
        {
            const image: ItemImage = value as ItemImage;
            images.push({original: image.regular, thumbnail: image.thumbnail} as GalleryImage);

        }
    }

    return images;
}