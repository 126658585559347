import {useEffect, useState} from "react";
import destinations, {DestinationModel} from "../models/destinationsModel";

export const useHomeDestinations = (): [destinations: DestinationModel[], destinationsLoading: boolean, destinationsEmpty: boolean] => {

    const [destinationList, setDestinations] = useState<DestinationModel[]>([]);
    const [destinationsLoading, setDestinationsLoading] = useState<boolean>(true);
    const [destinationsEmpty, setDestinationsEmpty] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {


            try {
                const response = await destinations.getRandom(4);
                setDestinations(response);
            }
            catch (error) {
                setDestinationsEmpty(true);
                setDestinations([]);
            }

        };

        fetchData().then(r => setDestinationsLoading(false));

    }, [destinationList.length]);

    return [destinationList, destinationsLoading, destinationsEmpty];

}