import {ResponseCourier} from "./responsesMuki";

export interface ErrorEntry {
    code: string | number | null;
    message: string;
}

export class ErrorContainer
{
    constructor(errors?: ErrorEntry[] | null) {

        if (errors !== null && errors !== undefined)
        {
            this.errors = errors;
        }
    }

    static create(errors?: ErrorEntry[] | null): ErrorContainer
    {
        return new ErrorContainer(errors);
    }

    protected errors: ErrorEntry[] = [];
    isError(): boolean
    {
        return false;
    }
    exportErrors(): [] | {} | null
    {
        if (this.errors.length === 1)
        {
            return this.errors[0] as {};
        }
        else if (this.errors.length > 1)
        {
            return this.errors as [];
        }
        return null;
    }

    importErrors(errors: any[]): ErrorContainer
    {
        if (errors.length > 0)
        {
            errors.forEach((error: any) => {

                if (error !== null && typeof error === "object" && Array.isArray(error) === false && error.hasOwnProperty('code') && error.hasOwnProperty('message'))
                {
                    this.addError(error.message as string, error.code as string | number);
                }
            });
        }

        return this;
    }

    addError(message: string, code?: string | number): ErrorContainer
    {
        this.errors.push({code, message} as ErrorEntry);
        return this;
    }

    setErrors(errors: ErrorEntry[]): ErrorContainer
    {
        this.errors = errors;
        return this;
    }

    getErrors(): ErrorEntry[]
    {
        return this.errors;
    }


    getErrorMessages(): Array<string> {
        return this.errors.map((error) => error.message);
    }
}

export class ErrorCourier extends Error {

    private _response: ResponseCourier;
    constructor(response: ResponseCourier, message?: string) {

        const errors = response.getErrorMessages();
        super(message !== null && message !== '' ? message : (errors[0] ? errors[0] : 'Error Desconocido'));
        this._response = response;
        this.name = this.constructor.name;
        Object.setPrototypeOf(this, new.target.prototype);
    }


    set response(value: ResponseCourier) {
        this._response = value;
    }

    get response(): ResponseCourier {
        return this._response;
    }
}