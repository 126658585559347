

const HomeIntro = () => (
    <div className="mxi-intro">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-7 col-sm-8 col-xs-10 text-center text-white">
                    <h3 className="text-orange fw-bolder">Explora nuevos horizontes con UDG Travel</h3>
                    <hr className="hr-dash lightblue"/>
                        <p className="lead">Descubre los destinos más emocionantes y sumérgete en culturas fascinantes. ¡Haz realidad tus sueños de viaje hoy mismo!</p>
                </div>
            </div>

{/*
            <ul className="nav nav-tabs features-tabs justify-content-center">
                <li className="nav-item mx-2">
                    <a className="nav-link active" href="" data-bs-toggle="tab" data-bs-target="#tab-pane-1"
                       type="button" role="tab" aria-controls="tab-pane-1" aria-selected="true"><span
                        className="mdi mdi-bus"></span></a>
                </li>
                <li className="nav-item mx-2">
                    <a className="nav-link" href="" data-bs-toggle="tab" data-bs-target="#tab-pane-2" type="button"
                       role="tab" aria-controls="tab-pane-2" aria-selected="true"><span className="mdi mdi-walk"></span></a>
                </li>
                <li className="nav-item mx-2">
                    <a className="nav-link" href="" data-bs-toggle="tab" data-bs-target="#tab-pane-3" type="button"
                       role="tab" aria-controls="tab-pane-3" aria-selected="true"><span
                        className="mdi mdi-car-hatchback"></span></a>
                </li>
                <li className="nav-item mx-2">
                    <a className="nav-link" href="" data-bs-toggle="tab" data-bs-target="#tab-pane-4" type="button"
                       role="tab" aria-controls="tab-pane-4" aria-selected="true"><span
                        className="mdi mdi-ferry"></span></a>
                </li>
                <li className="nav-item mx-2">
                    <a className="nav-link" href="" data-bs-toggle="tab" data-bs-target="#tab-pane-5" type="button"
                       role="tab" aria-controls="tab-pane-5" aria-selected="true"><span
                        className="mdi mdi-airplane"></span></a>
                </li>
            </ul>
*/}

        </div>
    </div>
);

export default HomeIntro;