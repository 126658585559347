import React, {useState} from "react";
import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";
import {Link} from "react-router-dom";
import {Accordion, Tab, Tabs} from 'react-bootstrap';
import DOMPurify from 'dompurify';
import ProductGallery from "./productGallery";
import {GalleryImage, galleryItems, placeholderImage} from "../../models/imagesModel";

export default function ProductDetails({ product, destination }: { product: ProductModel, destination: DestinationModel })
{

    const [activeTab, setActiveTab] = useState('tab1');

    function handleSelect(newTab: string | null) {
        if (newTab) {
            setActiveTab(newTab);
        }
    }

    const images: GalleryImage[] = galleryItems(product.ProductImages, destination.SPDestinationImages);

    return (

        <div className="container product-body">
            <div className="row">

                <div className="col-lg-8">

                    <Tabs activeKey={activeTab} onSelect={handleSelect} className="nav nav-mxi mb-3" id="mxi-product-main-info" role="tablist">

                        <Tab eventKey="tab1" className="nav-item" role="presentation" title="Detalles">

                            <div>
                                <h4 className="mb-4">Detalles del Paquete</h4>
                                <img src={product.ProductImage ? product.ProductImage : placeholderImage} alt={product.ProductName} style={{minWidth: "300px", maxWidth: "300px", maxHeight: "200px", borderRadius: "10px", float: "left", marginRight: "20px"}} />
                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.ProductContentFull as string) }} />
                            </div>

                        </Tab>

                        {product.ProductContentIncludes && (
                        <Tab eventKey="tab2" className="nav-item" role="presentation" title="Itinerario">

                            <Accordion  defaultActiveKey="0" flush id="mxi-product-itinerary">

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <h5 className="mb-0"><strong>Día 1</strong> &nbsp;&ndash;&nbsp; Barcelona - Zaragoza - Madrid</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dapibus odio ac pretium euismod. Ut eget dolor libero. Nulla dui tellus, consequat in turpis non, facilisis efficitur justo. Duis sagittis dapibus sollicitudin. Mauris urna tellus, vulputate sed vehicula eu, aliquam nec odio.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <h5 className="mb-0"><strong>Día 2</strong> &nbsp;&ndash;&nbsp; Zurich - Biel/Bienne - Neuchatel - Geneva</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Ut at mauris iaculis nibh vulputate rhoncus nec ut lorem. Mauris laoreet lorem sit amet accumsan ultrices. Donec volutpat sapien elit, id finibus massa rutrum ut. Quisque vestibulum vitae dui sit amet luctus. Quisque sed ligula at leo sagittis vehicula. Fusce egestas nisl sed metus finibus blandit.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <h5 className="mb-0"><strong>Día 3</strong> &nbsp;&ndash;&nbsp; Enchanting - Engelberg</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Aliquam feugiat posuere placerat. Donec risus ligula, hendrerit in metus vitae, pulvinar consectetur arcu. Aenean aliquet congue leo, ac scelerisque tellus. Mauris consectetur ligula non ante euismod gravida. Donec ornare ex ante. Morbi accumsan semper urna id bibendum. Curabitur mollis, odio vel interdum convallis, tortor massa semper neque, id euismod sapien sem eu nunc.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <h5 className="mb-0"><strong>Día 4</strong> &nbsp;&ndash;&nbsp; Barcelona - Zaragoza - Madrid</h5>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Suspendisse maximus justo quis fermentum tempus. Mauris ultrices laoreet enim, vitae fringilla ligula lobortis et. Fusce volutpat elit sit amet nulla dictum, nec egestas sem malesuada.</p>
                                        <p>Vestibulum luctus turpis eget elit semper faucibus. Curabitur scelerisque justo ac nibh cursus consectetur. Fusce ut dignissim tortor.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion >


                        </Tab>
                        )}

                        {product.ProductContentIncludes && (
                        <Tab eventKey="tab3" className="nav-item" role="presentation" title="Ubicación">

                            <h4 className="mb-4">Mapa</h4>
                            <div className="rounded overflow-hidden">
                                <div className="ratio ratio-16x9">
                                    <iframe className="" height="400"
                                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(mangal%20bazar)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                </div>
                            </div>

                        </Tab>
                        )}


                        {product.ProductContentIncludes && (
                        <Tab eventKey="tab4" className="nav-item" role="presentation" title="Notas">

                            <h5 className="mb-4">Notas:</h5>

                            <ul className="list-unstlyed">
                                <li className="d-flex">
                                    <span className="mdi mdi-information-outline text-green me-2"></span>
                                    <div>
                                        <p>Aliquam feugiat posuere placerat. Donec risus ligula, hendrerit in metus
                                            vitae, pulvinar consectetur arcu. Aenean aliquet congue leo, ac scelerisque
                                            tellus. Mauris consectetur ligula non ante euismod gravida. Donec ornare ex
                                            ante. Morbi accumsan semper urna id bibendum. Curabitur mollis, odio vel
                                            interdum convallis, tortor massa semper neque, id euismod sapien sem eu
                                            nunc. Integer sit amet felis ut tortor lacinia rutrum at quis dui. Cras
                                            consequat feugiat ligula, sit amet imperdiet sem dictum sit amet. Proin sit
                                            amet lacus non purus facilisis suscipit quis id nunc.</p>
                                    </div>
                                </li>
                                <li className="d-flex">
                                    <span className="mdi mdi-information-outline text-green me-2"></span>
                                    <div>
                                        <p>Aliquam convallis venenatis arcu et aliquet. Vivamus efficitur dolor elit,
                                            lobortis ullamcorper nunc ultrices et. Aenean fermentum consectetur sapien,
                                            vitae bibendum nisl.</p>
                                    </div>
                                </li>
                                <li className="d-flex">
                                    <span className="mdi mdi-information-outline text-green me-2"></span>
                                    <div>
                                        <p>Suspendisse maximus justo quis fermentum tempus. Mauris ultrices laoreet
                                            enim, vitae fringilla ligula lobortis et.</p>
                                        <p>Fusce volutpat elit sit amet nulla dictum, nec egestas sem malesuada.
                                            Vestibulum luctus turpis eget elit semper faucibus. Curabitur scelerisque
                                            justo ac nibh cursus consectetur. Fusce ut dignissim tortor. Ut rutrum
                                            ullamcorper felis. Aliquam porta consectetur placerat. Phasellus erat
                                            mauris, ultrices quis tincidunt vitae, pretium in lorem. Quisque id tempor
                                            dui, id accumsan enim. Phasellus eu nisi risus.</p>
                                    </div>
                                </li>
                                <li className="d-flex">
                                    <span className="mdi mdi-information-outline text-green me-2"></span>
                                    <div>
                                        <p>Aliquam erat volutpat. Sed non magna vel enim tristique molestie. Fusce
                                            semper sagittis fringilla.</p>
                                        <p>Nam vel nisl finibus, tristique risus rutrum, tincidunt ex. Sed eget
                                            scelerisque turpis, scelerisque vulputate nisl. Praesent turpis ex,
                                            consectetur et lacus sit amet, mattis tristique dui.</p>
                                        <p>Vestibulum congue porta felis, vel lacinia augue interdum ut. Donec consequat
                                            vitae est et gravida. Phasellus congue magna neque. In tempor eu dui sit
                                            amet placerat. In ullamcorper mauris sagittis dolor iaculis, ut ultrices
                                            augue molestie. Integer semper odio libero. Donec id turpis quis lacus
                                            fermentum suscipit et a erat. Morbi viverra purus a ante elementum rhoncus.
                                            Ut nisl tellus, viverra id est sit amet, sollicitudin scelerisque
                                            turpis.</p>
                                    </div>
                                </li>
                                <li className="d-flex">
                                    <span className="mdi mdi-information-outline text-green me-2"></span>
                                    <div>
                                        <p>Phasellus elementum velit eu placerat congue. Fusce eget lacus ac tortor
                                            fringilla tincidunt vel eget purus. Praesent vitae arcu mollis, finibus
                                            velit sed, suscipit leo. Ut molestie, est vel consequat porttitor, sapien
                                            libero sagittis ipsum, sed fermentum ipsum dui vel ipsum.</p>
                                        <p>Sed consectetur ex ac ante faucibus congue. Orci varius natoque penatibus et
                                            magnis dis parturient montes, nascetur ridiculus mus. Morbi metus metus,
                                            vulputate at mi nec, tempor tincidunt magna. Praesent vitae magna nec sem
                                            tristique scelerisque quis a erat. Etiam in egestas odio. Maecenas eros
                                            risus, sodales interdum mattis non, tempus ac odio. Vivamus a egestas
                                            ligula.</p>
                                    </div>
                                </li>
                            </ul>

                        </Tab>
                        )}

                        {images.length > 1 && (
                            <Tab eventKey="tab5" className="nav-item" role="presentation" title="Galería">
                                <ProductGallery product={product} destination={destination}></ProductGallery>
                            </Tab>
                        )}


                    </Tabs>



                </div>

                <div className="col-lg-4">
                    <div className="card mt-n5">
                        <div className="card-body">
                            <h5 className="text-center text-orange mb-4">Comienza tu compra</h5>
                            <form>
                                <div className="d-flex align-items-center my-3">
                                    <div className="flex-fill text-blue"><strong
                                        className="d-block">Adultos</strong><small
                                        className="d-block fs-8 text-secondary">(18 - 60)</small></div>
                                    <div className="mx-2"><input className="form-control form-control-sm" type="number"
                                                                 min="1" max="12" value="1" /></div>
                                    <span className="ms-2">$699</span>
                                </div>
                                <div className="d-flex align-items-center my-3">
                                    <div className="flex-fill text-blue"><strong
                                        className="d-block">Menores</strong><small
                                        className="d-block fs-8 text-secondary">(18 - 60)</small></div>
                                    <div className="mx-2"><input className="form-control form-control-sm" type="number"
                                                                 min="1" max="12" value="1" /></div>
                                    <span className="ms-2">$699</span>
                                </div>
                                <div className="d-flex align-items-center my-3">
                                    <div className="flex-fill text-blue"><strong
                                        className="d-block">Mayores</strong><small
                                        className="d-block fs-8 text-secondary">(18 - 60)</small></div>
                                    <div className="mx-2"><input className="form-control form-control-sm" type="number"
                                                                 min="1" max="12" value="1" /></div>
                                    <span className="ms-2">$699</span>
                                </div>

                                <hr className="mx-5 my-4" />

                                    <div className="mb-4">
                                        <label htmlFor="1"
                                               className="form-label text-blue fw-bold fs-7 mb-3">Salida</label>
                                        <select className="form-select" id="1" aria-describedby="">
                                            <option selected disabled>selecciona</option>
                                            <option value="Ago. 19 - Ago. 21">Ago. 19 - Ago. 21</option>
                                            <option value="Ago. 21 - Ago. 23">Ago. 21 - Ago. 23</option>
                                            <option value="Ago. 25 - Ago. 27">Ago. 25 - Ago. 27</option>
                                            <option value="Ago. 29 - Ago. 31">Ago. 29 - Ago. 31</option>
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="2" className="form-label text-blue fw-bold fs-7 mb-3">Hora de
                                            Salida</label>
                                        <select className="form-select" id="2" aria-describedby="">
                                            <option selected disabled>selecciona</option>
                                            <option value="9:00 am">9:00 am</option>
                                            <option value="11:00 am">11:00 am</option>
                                            <option value="2:00 pm">2:00 pm</option>
                                            <option value="4:00 pm">4:00 pm</option>
                                            <option value="6:00 pm">6:00 pm</option>
                                        </select>
                                    </div>

                                    <hr className="mx-5 my-4" />

                                        <div className="d-flex align-items-center justify-content-between fs-5 my-4">
                                            <span>TOTAL</span>
                                            <strong>$1.250 MXN</strong>
                                        </div>

                                        <Link to="reserva" className="btn btn-lg btn-orange rounded-1 d-block mx-n3 mb-n3 fs-7 fw-bold">Reservar</Link>

                            </form>
                        </div>
                    </div>

{/*

                    <a className="btn btn-lg btn-outline-blue rounded-1 d-block my-3 fs-7 fw-bold">Enviar Itinerario</a>

                    <a className="btn btn-lg btn-outline-blue rounded-1 d-block my-3 fs-7 fw-bold">Imprimir</a>

*/}

                </div>

            </div>
        </div>

    );
}