import React from "react";
import {ProductModel} from "../../models/productsModel";
import {Link} from "react-router-dom";
import {DestinationModel} from "../../models/destinationsModel";
import {placeholderImage} from "../../models/imagesModel";

export default function DestinationItem({ product, destination }: { product: ProductModel, destination: DestinationModel })
{

    return (
        <li className="col">
            <div className="card mxi-card">

                {/*
                <div className="card-frames topleft">
                    <div className="main-frame bg-orange text-white">
                        <small className="d-block fs-8">Desde</small>
                        <strong className="d-block"><s
                            className="opacity-50">$6000</s>$4500</strong>
                    </div>
                    <div className="small-frame bg-yellow text-blue d-inline-block">
                        <strong className="fs-7">PROMO</strong>
                    </div>
                </div>

                */}
                <div className="card-display ratio ratio-16x9"
                     style={{ "backgroundImage": `url(${product.ProductImage ? product.ProductImage : `'${placeholderImage}'`})`} as React.CSSProperties } >
                </div>
                <div className="card-body">
                    <h5><Link to={`/paquete/${product.ProductSlug}`} >{product.ProductName}</Link></h5>
                    <ul className="list-inline fs-7">
                        {product.ProductCategories && typeof product.ProductCategories === 'object' &&
                            Object.values(product.ProductCategories).map((category, index) => {
                                return (<li className="list-inline-item">{category}</li>)
                            })
                        }
                    </ul>
                    {parseInt(product.ProductDuration as string) > 0 &&
                        (<div className="d-flex align-items-center justify-content-between fs-8">
                            <div className="left"><span className="mdi mdi-clock-outline fs-8 me-1"></span>{product.ProductDuration} días
                            </div>
                        <div className="right"></div>
                        </div>
                        )}

                </div>
            </div>
        </li>
    )
}