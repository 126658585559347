import React from "react";
import {ProductModel} from "../../models/productsModel";
import {DestinationModel} from "../../models/destinationsModel";

export default function ProductHeader({ product, destination }: { product: ProductModel, destination: DestinationModel })
{
    return (

        <div className="product-header py-5 mb-3"
             style={{"backgroundImage": `url('${product.ProductImage ? product.ProductImage : 'https://source.unsplash.com/_Nsn2ScU6cY'}')`} as React.CSSProperties}>
            <div className="container">
                <div className="card rounded-3 bg-blue text-white shadow">
                    <div className="card-body px-5 d-flex align-items-center flex-column flex-md-row">
                        <div className="flex-fill">
                            <ul className="list-inline fs-8 mb-0">
                                <li className="list-inline-item"><a href="/" className="text-white"><i
                                    className="mdi mdi-home-outline fs-7"></i> Inicio</a></li>
                                <li className="list-inline-item text-white"> /</li>
                                <li className="list-inline-item"><a href={`/${destination.SPDestinationSlug}`}
                                                                    className="text-white">{destination.SPDestinationName}</a></li>
                                {/*
                                <li className="list-inline-item text-white"> /</li>
                                <li className="list-inline-item"><a href="javascript:void(0)" className="text-white"></a></li>
                                */}
                            </ul>
                            <h2>{product.ProductName}</h2>
                        </div>
                        <div className="">
                            <a href={`/paquete/${product.ProductSlug}/reserva`} className="btn btn-lg btn-orange px-5 rounded-0">Reservar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}