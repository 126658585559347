import {useEffect, useState} from "react";
import destinations, {DestinationModel} from "../models/destinationsModel";

export const useDestinations = (): [destinations: DestinationModel[], loading: boolean, empty: boolean] => {

    const [destinationList, setDestinations] = useState<DestinationModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [empty, setEmpty] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {


            try {
                const response = await destinations.getAll();
                setDestinations(response);
            }
            catch (error) {
                setEmpty(true);
                setDestinations([]);
            }

        };

        fetchData().then(r => setLoading(false));

    }, [destinationList.length]);

    return [destinationList, loading, empty];

}