import React from "react";
import {DestinationModel} from "../../models/destinationsModel";
import DOMPurify from 'dompurify';

export default function DestinationHeader({ destination }: { destination: DestinationModel })
{
    return (

        <div className="mxi-heading-background py-5 bg-cover bg-overlay-green"
             style={{ "backgroundImage": `url('${destination.SPDestinationImage ? destination.SPDestinationImage : 'https://source.unsplash.com/_Nsn2ScU6cY'}')`} as React.CSSProperties }>
            <div className="container">
                <ul className="mxi-breadcrumbs list-inline d-flex">
                    <li className="list-inline-item"><a className="text-white" href="/">Inicio</a></li>
                    <li className="list-inline-item"><a className="text-white" href="/">Catalogo</a></li>
                    <li className="list-inline-item"><a className="text-white" href="/">Paquetes</a></li>
                </ul>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="w-75">
                        <h1 className="text-white text-shadow mb-3">{destination.SPDestinationName}.</h1>
                        <p className="lead text-white text-shadow" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(destination.SPDestinationContentShort as string) }} />
                    </div>
                </div>
            </div>
        </div>

    )
}