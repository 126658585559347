import PageTitle from "./pageTitle.layout";
import React from "react";
import {Link} from "react-router-dom";

// recibe title y message
export default function ErrorPage({ title, message }: { title?: string, message?: string }) {

    title = title ?? "Página no encontrada";
    message = message ?? "La página que buscas no existe o no se encuentra disponible.";

    return (<>
            <PageTitle title={title} />

            <div className="py-5 bg-cover bg-overlay-green vh-100" style={{ "backgroundImage": "url(https://source.unsplash.com/random/1200x600/?tayrona)"} as React.CSSProperties }>
                <div className="container h-100">


                    <div className="d-flex h-100 align-items-center justify-content-center">
                        <div className="w-75 text-center">

                            <h1 className="text-white text-shadow display-1 my-4">{title}.</h1>
                            {message && (<p className="text-white lead my-4">{message}</p>)}
                            <hr className="hr-dash yellow"/>
                            <div className="d-flex align-items-center justify-content-center gap-3 my-4">
                                <Link to="/" className="btn btn-lg btn-outline-light"><span className="mdi mdi-arrow-left"></span> Regresa</Link>
                                <Link to="/" className="btn btn-lg btn-blue"><span className="mdi mdi-home-outline"></span> Inicio</Link>
                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </>
    );
}