import {Params, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import categories, {CategoryModel} from "../../models/categoriesModel";
import CategoryGallery from "./categoryGallery";
import CategoryItems from "./categoryItems";
import CategoryHeader from "./categoryHeader";
import PageTitle from "../system/layouts/pageTitle.layout";
import ErrorPage from "../system/layouts/errorPage";

interface CategoryParams extends Params{
    id: string;
}

const CategoryMain = () =>
{

    const { categoryId } = useParams<CategoryParams>();
    const [category, setCategory] = useState<CategoryModel>({})
    const [empty, setEmpty] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {

            const fetchData = async () => {

                if (categoryId !== '' && categoryId !== undefined)
                {
                    try {

                        const response = await categories.getCategory(categoryId as string);
                        setCategory(response);

                    }
                    catch (error) {
                        setCategory({});
                        setEmpty(true);
                    }
                    finally {
                        setLoading(false);
                    }

                }
        };

        fetchData();
    }, [categoryId]);

    if (loading)
    {
        return (<p>Cargando categoría...</p>)
    }
    else if (empty)
    {
        return (<ErrorPage />)
    }

    return (
        <>
            <PageTitle title={category.SPCategoryName} />
            <CategoryHeader category={category} />
            <CategoryGallery  category={category} />
            <CategoryItems  category={category} />
        </>
    )
}

export default CategoryMain;