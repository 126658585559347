import Home from "../components/home/home";
import {Route, Routes} from "react-router-dom";
import ErrorPage from "../components/system/layouts/errorPage";
import DestinationsMain from "../components/destinations/destinationsMain";
import DestinationMain from "../components/destination/destinationMain";
import AvisoPrivacidad from "../components/pages/avisoPrivacidad";
import TOC from "../components/pages/toc";
import Contacto from "../components/pages/contact";
import AboutUs from "../components/pages/aboutUs";
import ProductMain from "../components/product/productMain";
import CategoryMain from "../components/category/categoryMain";
import CategoriesMain from "../components/categories/categoriesMain";
import CheckoutMain from "./../components/store/checkoutMain";
import AccountRegister from "../components/account/register";
import FAQ from "../components/pages/faq";

export default function DefaultRoutes ()
{
        return (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route index element={<Home />} />
                <Route path="destinos" element={<DestinationsMain />} />
                <Route path="destinos/:destinationId" element={<DestinationMain />} />

                <Route path="categorias" element={<CategoriesMain />} />
                <Route path="categorias/:categoryId" element={<CategoryMain />} />

                <Route path="paquete/:id" element={<ProductMain />} />
                <Route path="paquete/:id/reserva" element={<CheckoutMain />} />

                <Route path="register" element={<AccountRegister />} />
                <Route path="nosotros" element={<AboutUs />} />
                <Route path="contacto" element={<Contacto />} />
                <Route path="cuenta" element={<Home />} />
                <Route path="terminos-y-condiciones" element={<TOC />} />
                <Route path="aviso-privacidad" element={<AvisoPrivacidad />} />
                <Route path="preguntas-frecuentes" element={<FAQ />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        );
}